/*
* @feedback.js
* @deprecated 
* @author czh
* @update (czh 2021/12/29)
*/
import request from '../utils/request'
//需求反馈-新增
export function addFeedbackRequire(query) {
    return request({
        url: '/api/af/help/require/feedback/add',
        method: 'post',
        params: query
    })
}