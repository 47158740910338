<template>

	<div class="left" @click="disapperMouseRight">

		<div class="left-header" :class="{ disabled: !user }">

			<span @click="increamnet('project')">

				<el-tooltip
					class="item"
					effect="dark"
					:content="$t('el.main.leftNav.increament.content1')"
					placement="top"
					popper-class="atooltip"
					:hide-after="1000"
				>

					<my-svg />

				</el-tooltip>

			</span>

			<span @click="increamnet('folder')">

				<el-tooltip
					class="item"
					effect="dark"
					:content="$t('el.main.leftNav.increament.content2')"
					placement="top"
					popper-class="atooltip"
					:hide-after="1000"
				>

					<my-svg icon="#icon-Newsubfolder" />

				</el-tooltip>

			</span>

			<span @click="increamnet('document')">

				<el-tooltip
					class="item"
					effect="dark"
					:content="$t('el.main.leftNav.increament.content3')"
					placement="top"
					popper-class="atooltip"
					:hide-after="1000"
				>

					<my-svg icon="#icon-Newdoc" />

				</el-tooltip>

			</span>

			<span>

				<el-tooltip
					class="item"
					effect="dark"
					:content="$t('el.main.leftNav.increament.content4')"
					placement="top"
					popper-class="atooltip"
					:hide-after="1000"
				>

					<div>

						<my-svg
							icon="#icon-Export"
							@click.native="verifyLogin"
						/>

						<input
							type="file"
							id="fileInput"
							multiple
							v-if="user"
							class="upload"
							ref="upload"
							@click="(e) => (e.target.value = '')"
							:accept="uploadExtension"
							@change="uploadLoca"
						/>

					</div>

				</el-tooltip>

			</span>

		</div>

		<div class="left-content">

			<div class="line"></div>

			<div
				class="dev-type-main-left"
				style="display:inline-block;
    min-width: 310px;"
			>

				<div v-show="showRightMenu">

					<ul id="menu" class="right-menu">

						<li
							v-if="rightMenuType"
							class="menu-item  special"
							@click="editHandle(true)"
						>
							 {{ $t('el.main.leftNav.edit.desc1') }}
						</li>

						<li
							v-else
							class="menu-item  special"
							@click="editHandle(false)"
						>
							 {{ $t('el.main.leftNav.edit.desc2') }}
						</li>

						<li class="menu-item" @click="delDevHandle">
							 {{ $t('el.main.leftNav.edit.desc3') }}
						</li>

					</ul>

				</div>

				<el-tree
					v-if="user"
					:data="data"
					:props="defaultProps"
					@node-click="handleNodeClick"
					:default-expanded-keys="expandedKeys"
					node-key="path"
					@node-expand="nodeExpand"
					@node-collapse="nodeCollapse"
					@node-contextmenu="delShow"
					:current-node-key="currentNodeKey"
					ref="my-tree"
				>

					<!-- :current-node-key='currentNodeKey' -->

					<span class="custom-tree-node" slot-scope="{ node, data }">

						<span>

							<my-svg
								:icon="
									data.category === 'project'
										? '#icon-proj'
										: data.category === 'folder'
										? '#icon-doc'
										: '#icon-s1'
								"
							/>

						</span>

						<span class="word">
							 {{ /默认项目/.test(node.label) && $i18n.locale === 'en_US'
							? 'DemoProject' : node.label }}
						</span>

					</span>

				</el-tree>

				<el-tree
					v-else
					:data="data"
					@node-click="pickNodeNoToken"
					:default-expanded-keys="expandedKeys"
					node-key="path"
					@node-expand="nodeExpand"
					@node-collapse="nodeCollapse"
					:current-node-key="currentNodeKey"
					ref="my-tree"
				>

					<!-- :current-node-key='currentNodeKey' -->

					<span class="custom-tree-node" slot-scope="{ node, data }">

						<span>

							<my-svg
								:icon="
									data.category === 'project'
										? '#icon-proj'
										: data.category === 'folder'
										? '#icon-doc'
										: '#icon-s1'
								"
							/>

						</span>

						<span class="word">
							 {{ /默认项目/.test(node.label) && $i18n.locale === 'en_US'
							? 'DemoProject' : node.label }}
						</span>

					</span>

				</el-tree>

				<div class="remain"></div>

			</div>

		</div>

		<!-- 创建项目 -->

		<el-dialog
			:visible.sync="createProjectShow"
			width="450px"
			:close-on-click-modal="false"
			@close="disappear('createProjectShow', 'createProject')"
		>

			<template slot="title">

				<span class="increamentItem">
					{{ $t("el.main.leftNav.create.desc1") }}
				</span>

			</template>

			<el-form
				:model="createProject"
				label-width="auto"
				:rules="projectRules"
				ref="createProject"
			>

				<el-form-item
					:label="$t('el.main.leftNav.create.desc2')"
					prop="label"
				>

					<el-input
						v-model="createProject.label"
						style="width: 290px"
						:placeholder="$t('el.main.leftNav.create.desc3')"
					></el-input>

				</el-form-item>

				<el-form-item
					:label="$t('el.main.leftNav.create.desc4')"
					prop="type"
				>

					<el-select
						v-model="createProject.type"
						:placeholder="$t('el.main.leftNav.create.desc5')"
						class="slectProject"
						style="width: 290px"
					>

						<el-option
							label="ETH"
							value="eth"
							style="width: 253px"
						></el-option>

						<el-option
							label="BCOS"
							value="bcos"
							style="width: 253px"
						></el-option>

						<el-option
							label="Fabric"
							value="fabric"
							style="width: 253px"
						></el-option>

						<el-option
							label="ChainMaker(solidity)"
							value="chainmaker"
							style="width: 253px"
						></el-option>

						<el-option
							label="ANTCHAIN"
							disabled
							value="antchain"
							style="width: 253px"
						></el-option>

					</el-select>

				</el-form-item>

			</el-form>

			<div slot="footer" class="dialog-footer">

				<div class="confitm" @click="handleProject">
					 {{ $t("el.main.leftNav.create.confirm") }}
				</div>

				<div
					class="concel"
					@click="disappear('createProjectShow', 'createProject')"
				>
					 {{ $t("el.main.leftNav.create.cancel") }}
				</div>

			</div>

		</el-dialog>

		<!-- 新建文件夹 -->

		<el-dialog
			:visible.sync="createFolderShow"
			width="450px"
			:close-on-click-modal="false"
			@close="disappear('createFolderShow', 'createFolder')"
		>

			<template slot="title">

				<span class="increamentItem">
					{{ $t("el.main.leftNav.create.desc7") }}
				</span>
			</template>

			<el-form
				:model="createFolder"
				label-width="auto"
				:rules="folderRules"
				ref="createFolder"
			>

				<el-form-item
					:label="$t('el.main.leftNav.create.desc8')"
					prop="label"
				>

					<el-input
						v-model="createFolder.label"
						style="width: 290px"
						:placeholder="$t('el.main.leftNav.create.desc3')"
					>

					</el-input>

				</el-form-item>

			</el-form>

			<div slot="footer" class="dialog-footer">

				<div @click="handleFolder">
					 {{ $t("el.main.leftNav.create.confirm") }}
				</div>

				<!-- <div @click="createFolderShow = false">
          {{ $t("el.main.leftNav.create.cancel") }}
        </div> -->

				<div @click="disappear('createFolderShow', 'createFolder')">
					 {{ $t("el.main.leftNav.create.cancel") }}
				</div>

			</div>

		</el-dialog>

		<!-- 新增合约文件名称 -->

		<el-dialog
			:visible.sync="createDocumentShow"
			width="500px"
			:close-on-click-modal="false"
			@close="disappear('createDocumentShow', 'createDocument')"
		>

			<template slot="title">

				<span class="increamentItem">
					{{ $t("el.main.leftNav.create.desc10") }}
				</span>

			</template>

			<el-form
				:model="createDocument"
				label-width="auto"
				:rules="documentRules"
				ref="createDocument"
			>

				<el-form-item
					:label="$t('el.main.leftNav.create.desc11')"
					prop="label"
				>

					<el-input
						v-model="createDocument.label"
						style="width: 290px"
						:placeholder="$t('el.main.leftNav.create.desc3')"
					></el-input>

					<span>{{ fileExtension }}</span>

				</el-form-item>

			</el-form>

			<div slot="footer" class="dialog-footer">

				<div @click="handleDocument">
					 {{ $t("el.main.leftNav.create.confirm") }}
				</div>

				<!-- <div @click="createDocumentShow = false">
          {{ $t("el.main.leftNav.create.cancel") }}
        </div> -->

				<div @click="disappear('createDocumentShow', 'createDocument')">
					 {{ $t("el.main.leftNav.create.cancel") }}
				</div>

			</div>

		</el-dialog>

		<!-- 删除弹出层 -->

		<div class="del">

			<el-dialog :visible.sync="delDialoge" width="410px">

				<div class="body">

					<div class="le">

						<img src="@/assets/images/triangle.png" />

					</div>

					<div class="ri">

						<div class="top">
							{{ $t("el.main.leftNav.create.desc13") }}
						</div>

						<div class="bottom">
							{{ $t("el.main.leftNav.create.desc14") }}
						</div>

					</div>

				</div>

				<div slot="footer" class="dialog-footer">

					<div @click="mouseRight">
						 {{ $t("el.main.leftNav.create.confirm") }}
					</div>

					<div @click="delDialoge = false">
						 {{ $t("el.main.leftNav.create.cancel") }}
					</div>

				</div>

			</el-dialog>

		</div>

		<!-- 修改项目 -->

		<el-dialog
			:visible.sync="editProjectShow"
			width="450px"
			:close-on-click-modal="false"
			@close="disappear('editProjectShow', 'editProjectForm')"
		>

			<template slot="title">

				<span class="increamentItem">
					{{ $t('el.main.leftNav.edit.desc1') }}
				</span>

			</template>

			<el-form
				:model="editProjectForm"
				label-width="auto"
				:rules="editProjectRules"
				ref="editProjectForm"
			>

				<el-form-item
					:label="$t('el.main.leftNav.create.desc2')"
					prop="label"
				>

					<el-input
						v-model="editProjectForm.label"
						style="width: 290px"
						:placeholder="$t('el.main.leftNav.create.desc3')"
					></el-input>

				</el-form-item>

				<el-form-item
					:label="$t('el.main.leftNav.create.desc4')"
					prop="type"
				>

					<el-select
						v-model="editProjectForm.type"
						:placeholder="$t('el.main.leftNav.create.desc5')"
						class="slectProject"
						style="width: 290px"
					>

						<el-option
							label="ETH"
							value="eth"
							style="width: 253px"
						></el-option>

						<el-option
							label="BCOS"
							value="bcos"
							style="width: 290px"
						></el-option>

						<el-option
							label="Fabric"
							value="fabric"
							style="width: 290px"
						></el-option>

						<el-option
							label="ChainMaker(solidity)"
							value="ChainMaker"
							style="width: 253px"
						></el-option>

						<el-option
							label="ANTCHAIN"
							disabled
							value="ANTCHAIN"
							style="width: 253px"
						></el-option>

					</el-select>

				</el-form-item>

			</el-form>

			<div slot="footer" class="dialog-footer">

				<div class="confitm" @click="updataProject">
					 {{ $t("el.main.leftNav.create.confirm") }}
				</div>

				<div
					class="concel"
					@click="disappear('editProjectShow', 'editProjectForm')"
				>
					 {{ $t("el.main.leftNav.create.cancel") }}
				</div>

			</div>

		</el-dialog>

		<!-- 重命名 -->

		<el-dialog
			:visible.sync="rename"
			width="500px"
			:close-on-click-modal="false"
			@close="disappear('rname', 'rnameForm')"
		>

			<template slot="title">

				<span class="increamentItem">
					{{ $t('el.main.leftNav.edit.desc2') }}
				</span>

			</template>

			<el-form
				:model="rnameForm"
				label-width="auto"
				:rules="renameRules"
				ref="rnameForm"
			>

				<el-form-item
					:label="
						renameType
							? $t('el.main.leftNav.create.desc8')
							: $t('el.main.leftNav.create.desc11')
					"
					prop="label"
				>

					<el-input
						v-model="rnameForm.label"
						style="width: 290px"
						:placeholder="$t('el.main.leftNav.create.desc3')"
					>

					</el-input>

					<span v-if="!renameType">{{ fileExtension }}</span>

				</el-form-item>

			</el-form>

			<div slot="footer" class="dialog-footer">

				<div @click="handleRename">
					 {{ $t("el.main.leftNav.create.confirm") }}
				</div>

				<div @click="disappear('rname', 'rnameForm')">
					 {{ $t("el.main.leftNav.create.cancel") }}
				</div>

			</div>

		</el-dialog>

	</div>

</template>

<script>
import $j from 'jquery'
//想了一想还是把全局变量存在localStorage中 this.$t("el.main.leftNav.create.desc6")
import mySvg from './svg.vue'
import { mapState, mapMutations } from 'vuex'
import {
	getData,
	setData,
	$success,
	$warning,
	$error,
	fileRules,
	strLen,
} from '@/utils/save'
import {
	createMenu,
	getFileData,
	getDatas,
	deleteMenu,
	queryDocument,
	createDocument,
	defaultMenu,
	menuRename,
	save,
	updateProject,
	ManyInquiries,
	getDefaultData,
} from '@/api/file.js'
export default {
	inject: ['toLogin'],
	data() {
		const validateProject = (rule, value, callback) => {
			if (!value) {
				callback(new Error(this.$t('el.main.leftNav.create.desc6')))
			} else if (value.length < 1 || value.length > 100) {
				callback(new Error(this.$t('el.main.leftNav.create.desc3')))
			} else if (fileRules(value)) {
				callback(
					new Error(
						this.$t('el.main.leftNav.create.desc2') +
							this.$t('el.main.leftNav.create.desc15')
					)
				)
			} else {
				callback()
			}
		}
		const validRenam = (rule, value, callback) => {
			let name = this.renameType
				? this.$t('el.main.leftNav.create.desc8')
				: this.$t('el.main.leftNav.create.desc16')
			if (!value) {
				callback(new Error(`${this.$t('el.input')}${name}`))
			} else if (value.length < 1 || value.length > 100) {
				callback(new Error(this.$t('el.main.leftNav.create.desc3')))
			} else if (fileRules(value)) {
				callback(
					new Error(
						`${name}${this.$t('el.main.leftNav.create.desc15')}`
					)
				)
			} else {
				callback()
			}
		}
		const validateFolder = (rule, value, callback) => {
			const self = this
			if (!value) {
				callback(new Error(this.$t('el.main.leftNav.create.desc9')))
			} else if (value.length < 1 || value.length > 100) {
				callback(new Error(this.$t('el.main.leftNav.create.desc3')))
			} else if (fileRules(value)) {
				callback(
					new Error(
						this.$t('el.main.leftNav.create.desc8') +
							this.$t('el.main.leftNav.create.desc15')
					)
				)
			} else {
				callback()
			}
		}
		const validateDocument = (rule, value, callback) => {
			const self = this
			// let len = this.fileExtension.length;
			// const remain = 20 - len;

			if (!value) {
				callback(new Error(this.$t('el.main.leftNav.create.desc12')))
			} else if (value.length < 1 || value.length > 100) {
				callback(new Error(this.$t('el.main.leftNav.create.desc3')))
			} else if (fileRules(value)) {
				callback(
					new Error(
						this.$t('el.main.leftNav.create.desc11') +
							this.$t('el.main.leftNav.create.desc15')
					)
				)
			} else {
				callback()
			}
		}
		const validateType = (rule, value, callback) => {
			if (!value) {
				callback(new Error(this.$t('el.main.leftNav.create.desc5')))
			} else {
				callback()
			}
		}
		return {
			//新建项目
			createProject: {
				label: '',
				type: '',
			},
			//创建项目弹出层是否可见
			createProjectShow: false,
			//新建项目约束
			projectRules: {
				label: [
					{
						required: true,
						validator: validateProject,
						trigger: 'blur',
					},
				],
				type: [
					{
						required: true,
						validator: validateType,
						trigger: 'change',
					},
				],
			},
			//新建文件夹
			createFolder: {
				label: '',
			},
			createFolderShow: false,
			folderRules: {
				label: [
					{
						required: true,
						validator: validateFolder,
						trigger: 'blur',
					},
				],
			},
			//新建合约文件
			createDocument: {
				label: '',
			},
			createDocumentShow: false,
			documentRules: {
				label: [
					{
						required: true,
						validator: validateDocument,
						trigger: 'blur',
					},
				],
			},
			//文件扩展名
			fileExtension: '.sol',
			//树结构数据
			data: [],
			//数组形状数据
			fileDate: [],
			//展开文件层级
			expandedKeys: [],
			//版本号
			defaultProps: {
				children: 'children',
				label: 'label',
			},
			//当前操作对象
			curOption: {},
			//删除弹出层
			delDialoge: false,
			//删除的节点
			node: {},
			//当前的删除操作，这个需要特殊处理
			curDelOption: {},
			documentPloceholder: '',
			//显示右键菜单
			showRightMenu: false,
			//修改项目
			editProjectShow: false,
			editProjectForm: {
				label: '',
				type: '',
			},
			oldEditProjectForm: {
				label: '',
				type: '',
			},
			editProjectRules: {
				label: [
					{
						required: true,
						validator: validateProject,
						trigger: 'blur',
					},
				],
				type: [
					{
						required: true,
						validator: validateProject,
						trigger: 'change',
					},
				],
			},
			//重命名
			rename: false,
			//右键菜单类别 true 项目 false其他
			rightMenuType: false,
			//修改的是文件 true文件夹，false文件
			renameType: false,
			rnameForm: {
				label: '',
			},
			renameRules: {
				label: [
					{ required: true, validator: validRenam, trigger: 'blur' },
				],
			},
			//以前的名字
			oldName: '',
			//是否执行
			execution: true,
			//当前选中key
			currentNodeKey: '',
			//是否是created
			isCreated: true,
			//打开文件的后缀名
			uploadExtension: '',
			//滚动条高度
			heightDistance: 0,
		}
	},
	computed: {
		user() {
			return this.$store.state.user
		},
		editableTabsValue: {
			get() {
				return this.$store.state.editableTabsValue
			},
			set(newValue) {
				this.changeEditorValue(newValue)
			},
		},
		curOptions: {
			get() {
				return this.$store.state.curOptions
			},
			set(value) {
				this.changeCurOption(value)
			},
		},
		...mapState(['editableTabs', 'tabIndex']),
	},
	methods: {
		...mapMutations(['addTab', 'changeCurOption']),
		pickNodeNoToken(data) {
			this.currentNodeKey = data.path
			this.$emit('clearForm', data.path)
			this.curOption = data
			const flag = data.type !== 'fabric'
			this.$emit('changeShowName', flag)
			this.showRightMenu = false
			this.changeCurOption(data)
			const state = this.$store.state
			state.isChangeEditror = true
			const { category, fileExtension } = data
			if (category === 'document') {
				state.isChangeEditror = true
				const haveTab = state.editableTabs.some(
					(item) => item.name === data.path
				)
				if (!haveTab) {
					state.editableTabs.push({
						title: data.label,
						name: data.path,
					})
				}
				//获得数值
				state.editableTabsValue = data.path
				const find = this.$store.state.fileDate.find(
					(it) => it.path === data.path
				)
				state.editor.setValue(find?.content || '')
				state.editor.setReadOnly(false)
				state.editor.gotoLine(1)
			}
			this.fileExtension = fileExtension
		},
		//点击左边文件
		handleNodeClick(data, $event, curNode) {
			this.currentNodeKey = data.path
			this.$emit('clearForm', data.path)
			this.curOption = data
			const path = data.path.split('/')[0]
			// const node=this.$store.state.fileDate.find(item=>item.path===path);
			const node = this.$store.state.fileDate.find(
				(item) => item.path === path
			)

			//上传文件类型
			this.uploadExtension = this.judgeExtension(node.type)
			const flag = node.type !== 'fabric'

			this.$emit('changeShowName', flag)

			//关闭右键菜单
			this.showRightMenu = false
			//不知道这里为什么要保存数据
			//  setData('fileDate',JSON.stringify( this.fileDate))
			this.changeCurOption(data)
			const { category, fileExtension } = data
			//如果是文档则添加按钮
			if (category === 'document') {
				this.addTab(data)
			}
			this.fileExtension = fileExtension
		},
		increamnet(option) {
			if (!this.user) {
				this.toLogin()
				return
			}

			if (Object.keys(this.curOption).length > 0) {
				const { level } = this.curOption
				if (level == 49 && option === 'folder') {
					return $error(this.$t('el.main.leftNav.create.error.err10'))
				}
				if (level === 50 && option === 'document')
					return $error(this.$t('el.main.leftNav.create.error.err11'))
			}
			if (option == 'project') {
				this.createProjectShow = true
			} else if (option === 'folder') {
				if (!Object.keys(this.curOption).length)
					return $error(this.$t('el.main.leftNav.create.error.err1'))
				//判断上一级是否是文件夹或者项目
				const { category } = this.curOption
				if (category === 'document')
					return $error(this.$t('el.main.leftNav.create.error.err2'))
				this.createFolderShow = true
			} else if (option === 'document') {
				if (!Object.keys(this.curOption).length)
					return $error(this.$t('el.main.leftNav.create.error.err3'))
				//判断上一级是否是文件夹或者项目
				const { category, children, path } = this.curOption
				const nodePath = path.split('/')[0]
				// const node = JSON.parse(getData("fileDate")).find(
				//   (item) => item.path === nodePath
				// );

				const node = this.$store.state.fileDate.find(
					(item) => item.path === nodePath
				)
				const { type } = node
				this.fileExtension = this.judgeExtension(type)

				if (category === 'document')
					return $error(this.$t('el.main.leftNav.create.error.err4'))
				this.createDocumentShow = true
			}
		},
		//判断类型方法
		judgeExtension(type) {
			switch (type) {
				case 'fabric':
					return '.go'
				default:
					return '.sol'
			}
		},
		//创建项目
		async handleProject() {
			const self = this
			await this.$refs['createProject'].validate()
			const { label, type } = this.createProject
			//判断项目名称是否重复
			let flag = this.data.some(
				(item) => item.label.trim() === label.trim()
			)
			if (flag) {
				return $error(this.$t('el.main.leftNav.create.error.err5'))
			}
			//文件类型
			let fileExtension = this.judgeExtension(type)
			let info = {
				label,
				type,
				level: 1,
				category: 'project',
				fileExtension,
				path: label,
			}
			if (!this.execution) return
			this.execution = false
			createMenu(info)
				.then((res) => {
					info.id = res.data
					$success(this.$t('el.main.leftNav.create.success'))
					self.data.push(info)
					self.createProjectShow = false
					self.fileExtension = fileExtension
					//重置表单
					self.$refs['createProject'].resetFields()
					//把项目也存储起来,先判断有没有
					// self.fileDate.push(info);

					// // setData("fileDate", JSON.stringify(self.fileDate));
					//  this.$store.commit('changeFileDate', self.fileDate)

					setData('data', JSON.stringify(self.data))
					self.expandedKeys.push(label)
					setData('expandedKeys', JSON.stringify(self.expandedKeys))

					self.initData()
				})
				.finally(() => (this.execution = true))
		},
		//新增文件夹
		handleFolder() {
			//这里修改
			/***
			 * 修改类型从最外层拿取到
			 * 由类型决定文件后缀名
			 */
			const self = this
			const { path } = this.curOption
			const nodePath = path.split('/')[0]
			// const node = JSON.parse(getData("fileDate")).find(
			//   (item) => item.path === nodePath
			// );

			const node = this.$store.state.fileDate.find(
				(item) => item.path === nodePath
			)
			const { type, fileExtension } = node
			this.$refs['createFolder'].validate((valid) => {
				if (valid) {
					const data = this.curOption
					let { level, category, path } = data
					// const { id } = JSON.parse(getData("fileDate")).find(
					//   (item) => item.path === path
					// );

					const { id } = this.$store.state.fileDate.find(
						(item) => item.path === path
					)
					const { label } = this.createFolder
					const flag = this.curOption.children
						? this.curOption.children.some(
								(item) => item.label.trim() === label.trim()
						  )
						: false
					if (flag)
						return $error(
							this.$t('el.main.leftNav.create.error.err6')
						)
					if (category === 'document')
						return $error(
							this.$t('el.main.leftNav.create.error.err7')
						)
					const newChild = {
						label,
						children: [],
						category: 'folder',
						level: level + 1,
						type,
						fileExtension,
						path: path + `/${label}`,
					}

					if (!data.children) {
						this.$set(data, 'children', [])
					}
					let curLight = path

					setData('expandedKeys', JSON.stringify(this.expandedKeys))
					//将数据存到数据库中

					if (this.execution) {
						createMenu({
							parentId: id,
							label,
							type,
							level: level + 1,
							category: 'folder',
							fileExtension,
							path: path + `/${label}`,
						})
							.then((res) => {
								this.createFolderShow = false
								this.createFolder.label = ''
								this.$refs['createFolder'].resetFields()
								data.children.push({
									label,
									category: 'folder',
									level: level + 1,
									type,
									fileExtension,
									path: path + `/${label}`,
								})
								this.expandedKeys.push(path + `/${label}`)
								$success(
									this.$t('el.main.leftNav.create.success')
								)
								// self.fileDate.push({ ...newChild, id: res.data });
								//   this.$store.commit('changeFileDate',self.fileDate)
								self.initData()
								// setData("fileDate", JSON.stringify(self.fileDate));
								self.execution = true
							})
							.finally(() => (this.execution = true))
					}

					//存储树状数据结构，存储文件数据
					setData('data', JSON.stringify(this.data))
					self.execution = false
					// self.initData();
				}
			})
		},
		//type 需要改变，extension需要改变
		//新增文件
		handleDocument() {
			const self = this
			this.$refs['createDocument'].validate((valid) => {
				if (valid) {
					let { label } = this.createDocument
					const data = this.curOption
					let { level, path } = data
					const nodePath = path.split('/')[0]
					// const node = JSON.parse(getData("fileDate")).find(
					//   (item) => item.path === nodePath
					// );

					const node = this.$store.state.fileDate.find(
						(item) => item.path === nodePath
					)
					const { type } = node
					// const { id } = JSON.parse(getData("fileDate")).find(
					//   (item) => item.path === path
					// );
					const { id } = this.$store.state.fileDate.find(
						(item) => item.path === path
					)

					//获得文件后缀名，后缀名
					const fileExtension = this.judgeExtension(type)
					//需要判断文件名称是否相同
					label = label + this.fileExtension
					let currLight = path
					path = path + `/${label}`
					// const flag=this.$store.state.fileDate.some(item=>item.path===path);
					const flag = this.$store.state.fileDate.some(
						(item) => item.path === path
					)
					if (flag)
						return $error(
							this.$t('el.main.leftNav.create.error.err8')
						)
					//数据库中新增文件
					if (this.execution) {
						createMenu({
							parentId: id,
							label,
							type,
							level: level + 1,
							category: 'document',
							fileExtension,
							path,
						})
							.then((res) => {
								self.currentNodeKey = currLight
								let newChild = {
									label,
									children: [],
									category: 'document',
									level: level + 1,
									type,
									fileExtension,
									path,
									content: '',
								}
								$success(
									this.$t('el.main.leftNav.create.success')
								)
								//创建成功之后去查询,看看文件中有没有代码内容
								self.fileDate.push({
									label,
									category: 'document',
									level: level + 1,
									type,
									fileExtension,
									path,
									id: res.data,
								})
								// setData("fileDate", JSON.stringify(self.fileDate));
								self.execution = true
								newChild.id = res.data
								setData(
									'expandedKeys',
									JSON.stringify(this.expandedKeys)
								)
								let fileDate = [
									...this.$store.state.fileDate,
									newChild,
								]
								// setData('fileDate',JSON.stringify(fileDate))
								this.$store.commit('changeFileDate', fileDate)
								self.initData()

								if (!data.children) {
									this.$set(data, 'children', [])
								}
								data.children.push(newChild)

								this.createDocumentShow = false
								// this.createDocument.label = "";
								this.$refs['createDocument'].resetFields()
								//将文件数据存储起来
								this.expandedKeys.push(path)

								this.changeCurOption({ ...this.curOption })
							})
							.finally(() => (this.execution = true))
					}
					this.execution = false

					// this.initData();
				}
			})
		},
		//展开节点
		nodeExpand(data) {
			this.expandedKeys.push(data.path)
			setData('expandedKeys', JSON.stringify(this.expandedKeys))
		},
		//关闭节点
		nodeCollapse(data) {
			if (this.expandedKeys.length > 0) {
				this.expandedKeys = this.expandedKeys.filter(
					(item) => item.indexOf(data.path) !== 0
				)
				setData('expandedKeys', JSON.stringify(this.expandedKeys))
			}
		},
		//显示删除文件
		delShow(event, cur, node, nodeSelf) {
			const self = this
			this.curDelOption = cur
			// //触发一点击事件
			// this.delDialoge = true;
			this.node = node

			this.rightMenuType = cur.category === 'project'
			this.renameType = cur.category === 'folder'

			let menu = document.querySelector('#menu')
			const left = event.clientX + 26
			const top = event.clientY - 6
			const leftCountHeight = $j('.left-content').height()

			const heightDistance = top - leftCountHeight
			if (heightDistance > 80 && $j('.left-content').scrollTop() == 0) {
				if (heightDistance > 80) {
					$j('.left-content')[0].scrollTop = heightDistance
					$j('#menu').css({ top: top - 90 + 'px', left: left + 'px' })
				} else {
					$j('#menu').css({
						top: top - heightDistance + 'px',
						left: left + 'px',
					})
				}
				this.heightDistance = heightDistance
				this.showRightMenu = true
			} else {
				$j('#menu').css({ top: top + 'px', left: left + 'px' })
				this.showRightMenu = true
			}
			var srcollTop = $j('.left-content').scrollTop()
			$j('.left-content').on('scroll.unable', function(e) {
				$j('.left-content').scrollTop(srcollTop)
			})
		},

		//鼠标右键
		mouseRight() {
			const self = this
			// const { path, category, label, level} = this.curOption;
			const { path, category, label, level } = this.curDelOption

			// const { id } = JSON.parse(getData("fileDate")).find(
			//   (item) => item.path === path
			// );

			const { id } = this.$store.state.fileDate.find(
				(item) => item.path === path
			)

			//删除tabs栏中的数据
			//删除文件路径
			// debugger;
			//这里需要区分文件夹和文件
			let delTabs
			if (category !== 'document') {
				// delTabs = JSON.parse(getData("fileDate"))
				//   .filter(
				//     (item) =>
				//       item.path.indexOf(path) == 0 &&
				//       item.level !== level &&
				//       item.category == "document"
				//   )
				//   .map((item) => item.path);

				delTabs = self.$store.state.fileDate
					.filter(
						(item) =>
							item.path.indexOf(path) == 0 &&
							item.level !== level &&
							item.category == 'document'
					)
					.map((item) => item.path)
			} else {
				// self.$store.state.fileDate
				delTabs = self.$store.state.fileDate
					.filter((item) => item.path === path)
					.map((item) => item.path)
			}

			//这里存在一个bug文件夹和文件同名的时候，这种删除会吧文件删除掉了
			let fileDel = self.$store.state.fileDate.filter(
				(item) => item.path.indexOf(path) === 0
			)
			if (category === 'folder') {
				fileDel = self.$store.state.fileDate.filter(
					(item) =>
						!(
							item.label.indexOf(label) === 0 &&
							item.category === 'document'
						)
				)
			}
			//本地删除，删除文件夹，可能文件夹同级别存在同名开头的文件
			if (category === 'folder') {
				this.fileDate = self.$store.state.fileDate.filter(
					(item) =>
						item.path.indexOf(path) !== 0 ||
						(item.path.indexOf(path) === 0 &&
							item.label.indexOf(label) === 0 &&
							item.category === 'document' &&
							item.level === level)
				)
			} else {
				this.fileDate = self.$store.state.fileDate.filter(
					(item) => item.path.indexOf(path) !== 0
				)
			}
			//这是需要从接口处删除的数据

			deleteMenu({ id }).then((res) => {
				$success(this.$t('el.main.leftNav.create.deleteSuccess'))
				self.$store.state.totalComponent.clearForm()
				self.initData('delete')
				//删除成功之后重新初始化数据
				this.delDialoge = false
				//查看 editableTabs中有哪些path 把有的path 关闭掉
				const shouldColose = self.$store.state.editableTabs.filter(
					(item) => delTabs.indexOf(item.name) !== -1
				)
				if (shouldColose.length > 0) {
					shouldColose.forEach((item) =>
						self.$store.commit('removeTab', item.name)
					)
				}
				//删除树状结构当中的数据
				const parent = self.node.parent
				const children = parent.data.children || parent.data
				const index = children.findIndex(
					(d) => d.id === self.curOption.id
				)
				children.splice(index, 1)

				//  this.delDialoge = false;

				//删除当前的被选中
				if (self.curDelOption.path === self.curOption.path) {
					self.curOption = {}
				}
				self.curDelOption = {}
			})
			// 从接口中删除
			// setData("fileDate", JSON.stringify(this.fileDate));
			//删除树状结构当中的数据
			// const parent = this.node.parent;
			// const children = parent.data.children || parent.data;
			// const index = children.findIndex((d) => d.id === this.curOption.id);
			// children.splice(index, 1);
			// this.delDialoge = false;
			// setData("data", JSON.stringify(this.data));
			//删除打开的扩展
			this.expandedKeys = this.expandedKeys.filter(
				(item) => item.indexOf(path) !== 0
			)
			setData('expandedKeys', JSON.stringify(this.expandedKeys))
			// this.initData();
		},
		//上传
		upload() {
			const option = this.curOption
			if (!Object.keys(option).length)
				return $error(this.$t('el.main.leftNav.create.desc17'))

			let upload = JSON.parse(getData('fileDate')).filter(
				(item) =>
					item.path.indexOf(option.path) !== -1 &&
					item.category === 'document'
			)
			//上传文件是改变后的文件
			upload = upload.filter((item) => item.content)
			upload = upload.map((item) => {
				const { id, label, type, content } = item
				return {
					contractFileId: id,
					contractFileName: label,
					contractType: type,
					code: content,
				}
			})
			// return ;
			if (upload.length > 0) {
				save([...upload]).then((res) => {
					$success(this.$t('el.saveSuccess'))
				})
			} else {
				// $error("没有文件更新");
			}
		},
		//关闭表单弹出层，关闭表单验证
		disappear(show, form) {
			this.rename = false
			this[show] = false
			this.$refs[form].resetFields()
		},
		//初始化数据
		initData(type) {
			const self = this
			// if(this.$refs["my-tree"]){
			//   this.$refs["my-tree"].setCurrentKey( this.curOption.path);

			// }
			// this.$refs["my-tree"].setCurrentKey( this.curOptions.path)
			function fn() {
				//获得fileData
				return getFileData().then((res) => {
					//这里如果fileData为空则需要创建默认文件夹
					if (!res.data.length) {
						defaultMenu().then((res) => {
							fn()
						})
					} else {
						self.fileDate = res.data
						// setData("fileDate", JSON.stringify(res.data));
						self.$store.commit('changeFileDate', res.data)
						//获得所有的data
						let needContent = res.data.filter(
							(item) =>
								!item.content && item.category == 'document'
						)
						// ManyInquiries
						ManyInquiries({
							contractFileIds: needContent.map((item) => item.id),
						}).then((res) => {
							const { data } = res
							data.forEach((item) => {
								const results = self.$store.state.fileDate.map(
									(v) => {
										if (v.id === item.contractFileId) {
											return { ...v, content: item.code }
										}
										return v
									}
								)

								self.$store.commit('changeFileDate', results)
								if (type === 'document') {
									//这里证明修改了文件名

									let selectFile = self.$store.state.fileDate.filter(
										(item) =>
											item.path ===
											self.$store.state.editableTabsValue
									)
									self.$store.state.totalComponent.files = selectFile.map(
										(item) => ({
											label: item.label,
											value: item.path,
										})
									)
								}
								if (type == 'delete') {
									self.$nextTick(() => {
										self.$refs['my-tree'].setCurrentKey(
											self.$store.state.activeName
										)
										//把地址改过来

										let selectFile = self.$store.state.fileDate.filter(
											(item) =>
												item.path ===
												self.$store.state.activeName
										)
										self.$store.state.totalComponent.files = selectFile.map(
											(item) => ({
												label: item.label,
												value: item.path,
											})
										)
									})
								}
							})
						})

						getDatas().then((res) => {
							//这里注释了date的重新赋值

							self.data = res.data
							setData('data', JSON.stringify(res.data))
							self.$nextTick(() => {
								let node = self.$store.state.fileDate.find(
									(item) => item.id === self.curOption.id
								)
								if (node) {
									self.currentNodeKey = node.path
									self.$refs['my-tree'].setCurrentKey(
										node.path
									)
								}
							})
						})
					}
				})
			}
			// 没有token时
			const getNoTokenData = () => {
				const getList = (node) => {
					let children = node.children
					node.content = node.documentCode
					delete node.documentCode
					this.fileDate.push(node)
					if (children) {
						for (let it of children) {
							getList(it)
						}
					}
				}
				getDefaultData().then((res) => {
					this.data = res.data
					this.fileDate = []
					for (let it of res.data) {
						getList(it)
					}
					this.$store.commit('changeFileDate', this.fileDate)
				})
			}
			if (localStorage.token) {
				fn().catch((err) => {
					if (err.message.includes('token')) {
						getNoTokenData()
					}
				})
			} else {
				getNoTokenData()
			}
		},
		//修改项目
		editHandle(flag) {
			if (flag) {
				const { label, type, path } = this.curDelOption

				this.editProjectShow = true

				this.editProjectForm = {
					label,
					type,
				}
				this.oldEditProjectForm = { label, type }
			} else {
				//赋予后缀名和文件名
				let { label, fileExtension, path } = this.curDelOption
				let arr = label.split('.')
				let ext = '.' + arr[arr.length - 1]
				let num = label.indexOf(ext)
				let name = label.slice(0, num)
				if (this.renameType) name = label
				this.oldName = name
				this.rnameForm.label = name
				this.fileExtension = fileExtension
				this.rename = true
			}
			this.showRightMenu = false
		},
		//删除项目
		delDevHandle() {
			this.delDialoge = true
			this.showRightMenu = false
		},
		//重命名
		handleRename() {
			const self = this
			let { fileExtension, renameType, curDelOption, oldName } = this
			let { label } = this.rnameForm
			if (label.trim() === oldName.trim()) {
				return $error(this.$t('el.main.leftNav.create.error.err13'))
			}

			let { path, id } = curDelOption
			let arr = path.split('/')
			let fatherPath = arr.filter((v, i) => i <= arr.length - 2).join('/')
			const father = this.$store.state.fileDate.find(
				(item) => item.path === fatherPath
			)
			const { type } = this.$store.state.fileDate.find(
				(item) => item.path === father.path.split('/')[0]
			)
			const name = (label + (renameType ? ' ' : fileExtension)).trim()
			const paths =
				father.path +
				'/' +
				label +
				(renameType ? ' ' : fileExtension).trim()
			//看看名字修改之后是否有重复
			const isRepeact = this.$store.state.fileDate.some(
				(item) => item.path === paths
			)
			if (isRepeact) {
				if (renameType) {
					return $error(this.$t('el.main.leftNav.create.error.err14'))
				} else {
					return $error(this.$t('el.main.leftNav.create.error.err15'))
				}
			}

			this.$refs['rnameForm'].validate((valid) => {
				if (valid && self.execution) {
					self.rename = false
					menuRename({
						parentId: father.id,
						category: renameType ? 'folder' : 'document',
						label: name,
						type,
						path: paths,
						id,
					})
						.then((res) => {
							//  self.initData();
							//  self.curDelOption={...self.curDelOption,label:name,path:paths}

							setTimeout(() => {
								$success(
									this.$t(
										'el.main.leftNav.create.renameSuccess'
									)
								)
							}, 500)
							self.expandedKeys.push(paths)
							self.$store.state.totalComponent.clearForm()
							setData(
								'expandedKeys',
								JSON.stringify(self.expandedKeys)
							)
							//这里需要改tab栏中的标签名字,如果标签栏中标签存在的话
							//这里需要考录改的是项目或者是文件的话，需要把项目或者文件下所有的标签改了

							//文档
							if (!renameType) {
								self.initData('document')

								let num
								const tabs = self.$store.state.editableTabs.some(
									(item, i) => {
										num = i
										return (
											item.title ==
											oldName + fileExtension
										)
									}
								)
								if (tabs) {
									const resultTabs = self.$store.state.editableTabs.map(
										(item, i) => {
											if (i === num) {
												if (
													this.$store.state
														.editableTabsValue ===
													path
												) {
													self.$store.commit(
														'changeEditableTabsValue',
														paths
													)
												}
												return {
													...item,
													title: name,
													name: paths,
												}
											}
											return item
										}
									)
									self.$store.commit(
										'changeEditableTabs',
										resultTabs
									)
								}
							} else {
								self.initData()
								//文件夹
								const resultTabs = self.$store.state.editableTabs.map(
									(item) => {
										//判断这个tabs中是否有这个路径  a/b/c.sol  a/b
										let falg = false
										let begin =
											item.name.indexOf(path) !== -1
										let itemPaths = item.name.split('/')
										let pathArr = path.split('/')
										let len = pathArr.length
										let middle =
											itemPaths[len - 1] &&
											itemPaths[len - 1] ===
												pathArr[len - 1]

										if (begin && middle) {
											let newName = item.name
												.split('/')
												.map((v, i) => {
													if (i === len - 1) {
														return paths.split('/')[
															i
														]
													}
													return v
												})
												.join('/')
											let oldNamePath = path.split('/')
											let length = oldNamePath.length
											let info = self.$store.state.editableTabsValue.split(
												'/'
											)
											let judgeInfo =
												oldNamePath[length - 1] ===
												info[length - 1]
											console.log(path, 'oldNamePath')
											console.log(
												self.$store.state
													.editableTabsValue,
												'self.$store.state.editableTabsValue'
											)
											console.log(judgeInfo, 'judgeInfo')
											if (
												self.$store.state.editableTabsValue.indexOf(
													oldName
												) !== -1 &&
												judgeInfo
											) {
												self.$store.commit(
													'changeEditableTabsValue',
													newName
												)
											}

											return { ...item, name: newName }
										}
										return { ...item }
									}
								)
								self.$store.commit(
									'changeEditableTabs',
									resultTabs
								)
							}
						})
						.finally(() => {
							self.execution = true
						})
					self.execution = false
				}
			})
		},
		//修改项目
		updataProject() {
			const { label, type, path } = this.editProjectForm
			const { id } = this.curDelOption
			//其中要至少一个不同
			const self = this
			this.$refs['editProjectForm'].validate((valid) => {
				if (valid) {
					if (
						self.oldEditProjectForm.label === label &&
						self.oldEditProjectForm.type === type
					) {
						return $error(
							this.$t('el.main.leftNav.create.error.err14')
						)
					}
					//判断是否修改后和以前存在的重名字了
					const currentModify = this.$store.state.fileDate.find(
						(item) => item.label === label
					)

					if (currentModify && self.oldEditProjectForm.type === type)
						return $error('同名项目已存在')
					self.editProjectShow = false
					if (self.execution) {
						updateProject({
							label,
							type,
							id,
						}).then((res) => {
							self.initData()
							self.expandedKeys.push(label)
							setData(
								'expandedKeys',
								JSON.stringify(self.expandedKeys)
							)
							setTimeout(() => {
								$success(
									this.$t(
										'el.main.leftNav.create.editSuccess'
									)
								)
								self.$store.state.totalComponent.clearForm()
								self.execution = true
								//修改了名字之后再进行
								let newName = ''
								if (self.oldEditProjectForm.label !== label) {
									const resultTabs = self.$store.state.editableTabs.map(
										(item) => {
											const oldPath =
												self.oldEditProjectForm.label
											const pathArr = item.name.split('/')
											const isSome =
												oldPath === pathArr[0]
											if (isSome) {
												newName = pathArr
													.map((v, i) => {
														if (i == 0) {
															return label
														}
														return v
													})
													.join('/')
												return {
													...item,
													name: newName,
												}
											}
											return item
										}
									)
									self.$store.commit(
										'changeEditableTabs',
										resultTabs
									)
									let info = self.$store.state.editableTabsValue.split(
										'/'
									)
									let judgeInfo =
										self.curDelOption.path === info[0]
									if (judgeInfo) {
										self.$store.commit(
											'changeEditableTabsValue',
											newName
										)
									}
								}
							}, 500)
						})
					}

					self.execution = false
				}
			})
		},
		verifyLogin() {
			if (!this.user) {
				this.toLogin()
			}
		},
		//上传本地文件
		uploadLoca(e) {
			const self = this

			let options = this.curOption
			if (!Object.keys(options).length) {
				e.target.value = ''
				return $error(this.$t('el.main.leftNav.create.error.err3'))
			}
			const { category, level, path } = this.curOption
			const { id } = this.$store.state.fileDate.find(
				(item) => item.path === path
			)

			if (category === 'document') {
				return $error(this.$t('el.main.leftNav.create.error.err4'))
			}
			const node = this.$store.state.fileDate.find(
				(item) => item.path === path
			)

			let files = this.$refs['upload'].files

			// return ;
			//文件名字不能超过二十个字符，文件后缀名合法，文件名中不能存在.,文件名不能重复。在同一个文件夹下
			let fileList = []
			files = [...files]
			let sizeBeyond =
				files.reduce((prev, cur) => prev + cur.size, 0) / 1024 > 1024
			if (sizeBeyond) {
				return $error(this.$t('el.main.leftNav.create.error.err16'))
			}
			// 文件个数的限制
			if (files.length > 10) {
				return $error(this.$t('el.main.leftNav.create.error.err17'))
			}

			let dotJudge = files.some((item) => {
				let arr = item.name.split('.')
				arr.pop()
				let result = arr.join('.')
				return result.indexOf('.') !== -1
			})
			// //uploadExtension  判断后缀名
			let typeJudge = files.some((item) => {
				let arr = item.name.split('.')
				let ext = arr[arr.length - 1]
				return '.' + ext !== self.uploadExtension
			})

			if (typeJudge) {
				return $error(this.$t('el.main.leftNav.create.error.err18'))
			}
			if (dotJudge) {
				return $error(this.$t('el.main.leftNav.create.error.err19'))
			}
			let lenJudge = files.some((item) => {
				let arr = item.name.split('.')
				arr.pop()
				let result = arr.join('.')
				return result.length > 100
			})
			if (lenJudge) {
				return $error(this.$t('el.main.leftNav.create.error.err20'))
			}

			//同级文件
			let currentFiles = this.$store.state.fileDate
				.filter((item) => {
					let pathArr = item.path.split('/')
					pathArr.pop()
					return pathArr.join('/') === path
				})
				.map((v) => v.label)

			const nameJudge = files.some(
				(item) => currentFiles.indexOf(item.name) !== -1
			)
			if (nameJudge) {
				return $error(this.$t('el.main.leftNav.create.error.err21'))
			}

			files.forEach((item, i) => {
				const fr = new FileReader()
				fr.readAsText(item)

				fr.onload = function() {
					const code = this.result
					createMenu({
						parentId: id,
						category: 'document',
						label: item.name,
						type: node.type,
						fileExtension: self.judgeExtension(node.tyep),
						level: level + 1,
						path: path + '/' + item.name,
					}).then((res) => {
						createDocument({
							contractFileId: res.data,
							contractFileName: 'item.name',
							contractType: node.type,
							code,
						}).then((response) => {
							self.initData()
							if (i === 0) {
								setTimeout(() => {
									$success(self.$t('el.uploadSuccess'))
								}, 500)
							}
							self.expandedKeys.push(path)
							setData(
								'expandedKeys',
								JSON.stringify(self.expandedKeys)
							)
							self.$store.state.totalComponent.clearForm()
						})
					})

					fileList.push({ name: item.name, content: this.result })
				}
			})
		},
		disapperMouseRight() {
			this.showRightMenu = false
			$j('.left-content').unbind()
		},
		init() {
			const self = this
			this.initData()
			const expandedKeys = getData('expandedKeys')
			this.expandedKeys = expandedKeys ? JSON.parse(expandedKeys) : []
			//向打开路径中添加默认文件的操作
			let defaultPath = [
				'默认项目',
				'默认项目/默认文件夹',
				'默认项目/默认文件夹/默认文件.sol',
			]
			this.expandedKeys = [...this.expandedKeys, ...defaultPath]
			setData('expandedKeys', JSON.stringify(this.expandedKeys))
		},
	},
	created() {
		this.init()
	},
	components: {
		mySvg,
	},
	mounted() {
		this.$store.commit('changeTreeNode', this.$refs['my-tree'])
		this.$store.commit('changeLeftNav', this)
		//  this.$refs["my-tree"].setCurrentKey( '默认项目');
		this.isCreated = false
		const self = this
		//  this.$nextTick(()=>{
		//       self.
		//  })
	},
	watch: {
		user(v) {
			if (v) this.init()
		},
		currentNodeKey(path) {
			if (path && path.toString()) {
				this.$refs['my-tree'].setCurrentKey(path)
			} else {
				this.$refs['my-tree'].setCurrentKey(null)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.left {
	width: 310px;
	height: calc(100vh - 77px);
	margin: 0 0 10px 10px;
	background: #2f3544;
	border-radius: 6px;
}

.left {
	display: flex;
	flex-direction: column;

	.left-header {
		width: 100%;
		height: 60px;
		display: flex;
		padding: 0 50px;
		box-sizing: border-box;
		justify-content: space-between;
		position: relative;

		::v-deep .icon {
			font-size: 26px;
			// color: white;
			color: #d3dcf5;
			margin-top: 21px;
			cursor: pointer;
		}

		::v-deep .icon:hover {
			color: #465ebf;
		}

		.word {
			font-size: 14px;
		}
	}

	.left-content {
		border-top: 2px solid #141720;
		padding-top: 30px;

		.right-menu {
			z-index: 2000;
			position: absolute;
			height: 78px;
			width: 150px;

			li {
				list-style: none;
				cursor: pointer;
			}

			.menu-item {
				//display: block;
				line-height: 36px;
				text-align: center;
				font-size: 14px;

				background: #676f84;
				color: #d3dcf5;

				&:hover {
					color: #606266;
					background: #ffffff;
					font-weight: 400;
				}

				&:hover::before {
					border-right: 10px solid #fff;
				}
			}

			// .special{
			//   background: #676F84;
			//   color: #D3DCF5;
			//   font-weight: 400;
			// }
		}

		.special::before {
			width: 0;
			height: 0;
			border-top: 9px solid transparent;
			border-right: 10px solid #676f84;
			border-bottom: 9px solid transparent;
			position: absolute;
			content: '';
			left: -9px;
			top: 9px;
		}

		//}

		margin-top: 2px;
		flex: 1;
		overflow: auto;

		::v-deep .icon {
			font-size: 18px;
			line-height: 26px;
		}
	}
}

.upload {
	width: 28px;
	height: 20px;
	position: absolute;
	z-index: 10;
	cursor: pointer;
	font-size: 0;
	background-color: transparent;
	top: 24px;
	right: 48px;
	color: transparent;
	border: none;
	outline: none;
	box-shadow: none;
	opacity: 0;
}

//导航条样式
.left-content::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

//新增项目样式
::v-deep .increamentItem {
	color: #30364e;
	font-size: 18px;
	font-weight: 700;
}

::v-deep .new {
	color: #30364e;
	font-size: 16px;
	font-weight: 400;
}

::v-deep .el-dialog__body {
	background: #f6f7f9;
}

::v-deep .el-dialog__footer {
	height: 80px;

	.dialog-footer {
		display: flex;
		padding: 15px 40px 0;
		justify-content: space-between;

		div {
			width: 100px;
			height: 30px;
			border: 1px solid #30364e;
			border-radius: 3px;
			color: #2a2f45;
			font-size: 16px;
			font-weight: 400;
			line-height: 30px;
			text-align: center;
			cursor: pointer;
			box-sizing: border-box;
		}

		div:first-child {
			background: #30364e;
			color: #fff;
		}
	}
}

::v-deep .el-dialog__footer {
	.dialog-footer {
		div:hover {
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.37);
		}
	}
}

//树状结构样式
::v-deep.el-tree {
	background: #2f3544;
	user-select: none;

	.icon {
		color: #d3dcf5;
	}

	.el-tree-node__content:hover {
		background: #505665;
	}

	.el-tree-node:focus > .el-tree-node__content,
	.el-tree-node:focus {
		background: #141720 !important;
	}

	.el-tree-node {
		background: #2f3544;
	}

	.custom-tree-node {
		span {
			font-size: 14px;
		}
	}

	.is-current {
		background-color: #141720 !important;
		// width: 180%;
	}

	.el-tree-node:focus > .el-tree-node__content {
		background-color: #141720;
	}

	.el-tree-node__content {
		color: #fff;
		height: auto !important;
	}

	.el-tree-node__children {
		overflow: visible;
	}
}

.dev-type-main-left::-webkit-scrollbar-thumb {
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
}

.remain {
	width: 100%;
	height: 70px;
	// background-color: pink;
}

.dev-type-main-left::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 5px #3d4659;
	box-shadow: 0;
	background-color: #343c4f;
}

//导航条样式
.left-content::-webkit-scrollbar-thumb {
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
	// background-color: #3d4659;
}

.left-content::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 5px #3d4659;
	box-shadow: 0;
	background-color: #343c4f;
	//   background-color: red;
}

.left-content::-webkit-scrollbar-corner {
	background-color: #3d4659;
}

.left-content::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 4px;
	/*高宽分别对应横竖滚动条的尺寸*/
	height: 4px;
	background-color: #343c4f;
}

.disabled {
	opacity: 0.2;
}

// .left-content::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
//     border-radius: 5px;
//     -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
//     background-color: #343c4f;;
// }

// //删除弹出层
::v-deep .del {
	.el-dialog__header {
		height: 30px;
		padding: 12px 0;
	}

	.body {
		display: flex;
		align-items: center;

		.le {
			margin-left: 116px;

			img {
				width: 46px;
			}
		}

		.ri {
			font-weight: 400;
			margin-left: 15px;
		}
	}
}
</style>

