<template>

	<div class="inner" @click="$store.state.leftNav.disapperMouseRight()">

		<Header />

		<div class="middle">

			<div class="left" style="overflow: hidden;">

				<left-nav
					@clearForm="clearForm"
					@changeShowName="changeShowName"
				/>

			</div>

			<div class="center">

				<div class="editor">

					<div class="tab">

						<div class="tabS">

							<el-tabs
								v-model="editableTabsValue"
								type="card"
								closable
								@tab-remove="removeTab"
								@tab-click="tabsClick"
							>

								<el-tab-pane
									v-for="item in editableTabs"
									:key="item.name"
									:label="item.title | formatLable"
									:name="item.name"
								>

								</el-tab-pane>

							</el-tabs>

						</div>

						<div class="saveBtn" v-if="user">

							<span
								class="saveOth"
								v-if="curOptions.defaultContract"
							>

								<el-tooltip
									class="item reset-btn"
									effect="dark"
									:content="$t('el.main.index.resetHover')"
									placement="top"
									popper-class="atooltip"
								>

									<span
										@click="handleReset"
										class="el-icon-refresh-right "
									></span>

								</el-tooltip>

							</span>

							<span class="saveOth">

								<el-tooltip
									class="item"
									effect="dark"
									:content="$t('el.main.index.saveHover')"
									placement="top"
									popper-class="atooltip"
								>

									<span @click="save">

										<my-svg icon="#icon-baocun" />

									</span>

								</el-tooltip>

							</span>

						</div>

					</div>

					<div class="edit-area">

						<editor />

					</div>

				</div>

				<div class="bottom">

					<div class="title" v-if="description.length > 0">
						 {{ $t('el.main.index.errorDesc') }}:
					</div>

					<div class="word" v-if="description.length > 0">
						 {{ description }}
					</div>

					<div class="title">
						 {{ $t('el.main.index.fixAdvice') }}:
					</div>

					<div class="word" v-if="advice.length > 0">
						 {{ advice }}
						<div class="wiki" v-if="wiki">

							<span class="err-doc-url" @click="open(wiki)">
								 {{ $t('el.main.index.errDoc') }}
							</span>

						</div>

					</div>

				</div>

			</div>

			<div class="right">

				<div class="form" v-if="phase === 'submit'">

					<el-form
						ref="submitInfo"
						:model="submitInfo"
						:rules="rules"
					>

						<el-form-item prop="file">

							<el-select
								v-model="submitInfo.file"
								:placeholder="
									$t('el.main.index.form.placeholder5E')
								"
								:popper-append-to-body="false"
								@visible-change="examine"
							>

								<el-option
									v-for="(i, index) in files"
									:key="index"
									:label="i.label"
									:value="i.value"
								>

								</el-option>

							</el-select>

						</el-form-item>

						<el-form-item prop="name" v-if="showName">

							<el-input
								v-model="submitInfo.name"
								:placeholder="
									$t('el.main.index.form.placeholder2E')
								"
								style="width: 268px"
							></el-input>

						</el-form-item>

						<el-form-item class="addArea" prop="params">

							<div
								class="flexs"
								v-for="(item, index) in params"
								:key="index"
								:class="{ unusual: index !== 0 }"
							>

								<el-input
									v-model="item.value"
									:class="
										$i18n.locale === 'en_US'
											? 'placeholder3E'
											: ''
									"
									:placeholder="
										$t('el.main.index.form.placeholder3E')
									"
									style="width: 228px"
								>

								</el-input>

								<my-svg
									icon="#icon-add"
									v-if="index === 0"
									:handle="() => addParams(true)"
								/>

								<my-svg
									icon="#icon-cut"
									v-else
									:handle="() => addParams(false, item.id)"
								/>

							</div>

						</el-form-item>

						<el-form-item prop="version">

							<el-select
								v-model="submitInfo.version"
								:placeholder="
									$t('el.main.index.form.placeholder4')
								"
								:popper-append-to-body="false"
								@visible-change="examine"
							>

								<el-option
									v-for="(item, index) in curVersion"
									:key="index"
									:label="item.value"
									:value="item.value"
								>

								</el-option>

							</el-select>

						</el-form-item>

						<div
							class="test"
							:class="{ testSpecial: freeTime == 0 }"
							@click="submit"
						>
							 {{ $t("el.main.index.desc.submit") }}
						</div>

					</el-form>

					<div class="remain">
						 {{ $t("el.main.index.desc.remain") }}{{ freeTime }}{{ $t("el.main.index.desc.word")
						}}
					</div>

					<div class="word-content">

						<div class="title">
							 {{ $t("el.main.index.desc.tip") }}
						</div>

						<div class="info">
							 {{ $t("el.main.index.desc.result") }} {{ $t('el.main.index.introduction')
							}}
						</div>

						<div class="info info-tip">

							<img src="@/assets/images/svg/email.svg" alt="" />

							<a href="mailto:market@lianantech.com">
								 market@lianantech.com
							</a>

						</div>

						<div class="info info-tip">

							<i class="el-icon-phone"></i>

							<a href="tel:028 83262585">028-83262585</a>

						</div>

						<div class="wx-content">

							<div class="wx-item">

								<div class="tip-title">微信公众号：</div>

								<img
									src="@/assets/images/wx-gongzhonghao.png"
									class="wx-img"
									alt=""
								/>

							</div>

							<div class="wx-item">

								<div class="tip-title">客服微信：</div>

								<img
									src="@/assets/images/wx-kefu.jpg"
									class="wx-img"
									alt=""
								/>

							</div>

						</div>

						<img
							src="@/assets/images/logo/logo-r.png"
							alt=""
							style="width: 200px;"
						/>

					</div>

				</div>

				<div class="check" v-if="phase == 'check'">

					<div class="circle">

						<el-progress
							type="circle"
							:percentage="percentage"
							:stroke-width="20"
							color="#5882FF"
							stroke-linecap="butt"
						></el-progress>

					</div>

					<div class="step" v-if="testProgress">
						 {{ $t("el.main.index.desc.checking") }}...
					</div>

					<div class="error">{{ errMsg }}</div>

					<div
						class="plase"
						v-if="errMsg && errMsg.length > 0 && showReSubmit"
					>
						 {{ $t("el.main.index.desc.errorF") }}
					</div>

					<!-- <div
                      class="return"
                      @click="curResultType === 0 ? handleStopTesting() : handleBack()"
                    >
                      {{resultTypeBtn[curResultType]}}
                    </div> -->

					<div class="submit-btn">

						<el-button
							:loading="submitLoading"
							@click="
								curResultType === 0
									? handleStopTesting()
									: handleBack()
							"
						>

							<span>
								 {{ curResultType === 0 ? $t('el.main.index.desc.stop')
								: $t('el.back') }}
							</span>

						</el-button>

					</div>

				</div>

				<div class="res" v-if="phase == 'result'">

					<div class="number"></div>

					<div class="word">
						 {{ $t("el.main.index.desc.checked") }}
					</div>

					<div class="sub-msg" v-if="curResultIsNoFull">
						 {{ $t("el.main.index.desc.tips1") }}
						<span style="color: #f01;">
							 {{ $t("el.main.index.desc.tips2") }}
						</span>
						 {{ $t("el.main.index.desc.tips3") }}
						<el-tooltip effect="light" placement="top">

							<div slot="content" style="line-height:26px">
								 Tel：
								<a href="tel:028 83262585">028-83262585</a>

								<br />
								 Email:
								<a href="mailto:market@lianantech.com">
									 market@lianantech.com
								</a>

							</div>

							<span
								class="el-icon-info"
								style="color: #465ebf;font-size:16px"
							></span>

						</el-tooltip>

					</div>

					<div class="error" v-if="errList.length">

						<div
							class="line"
							v-for="(item, index) in errList"
							:key="index"
						>

							<!-- <div class='line'>文件:{{item.file| formatFile}}</div> -->

							<div class="line">

								<template>

									<el-tooltip
										effect="light"
										:content="formatFileTooltip(item.file)"
										placement="top-start"
									>

										<div class="line">
											 {{ $t("el.file") }}:{{ formatFile(item.file)
											}}
										</div>

									</el-tooltip>

								</template>

							</div>

							<div
								class="line"
								:class="{
									'active-line': item.file + i === activeLine,
								}"
								v-for="(v, i) in item.data"
								:key="i"
								@click="toLine(v.line, v.title, item.file, i)"
							>
								 {{ i + 1 }}、{{ getStore('language') === 'en_US'
								? v.title : v.title_zh }}({{ v.line }}{{ $t("el.main.index.desc.row")
								}})
							</div>

						</div>

					</div>

					<div class="error" v-else>

						<div class="line">
							 {{ $t("el.main.index.desc.noProblem") }}
						</div>

					</div>

					<div class="bottom-btn">

						<div class="btn-arrea" @click="examineResult">
							 {{ $t("el.main.index.desc.reportScan") }}
						</div>

						<div
							class="btn-arrea btn-special"
							@click="examineReturn"
						>
							 {{ $t("el.back") }}
						</div>

					</div>

				</div>

			</div>

		</div>

		<!-- 查看结果弹出层 -->

		<div class="check">

			<el-dialog
				:visible.sync="showResult"
				custom-class="table"
				width="1180px"
				:close-on-click-modal="false"
			>

				<template slot="title">

					<span>{{ $t("el.main.index.result.history") }}</span>

					<span>Test results</span>

				</template>

				<el-form @submit.native.prevent>

					<el-form-item>

						<el-input
							v-model="fileNames"
							autocomplete="off"
							:placeholder="$t('el.main.index.form.placeholder6')"
							style="width: 463px"
							@keyup.native.enter="search"
						>

							<i
								slot="suffix"
								class="el-input__icon el-icon-search"
								@click="search"
							></i>

						</el-input>

					</el-form-item>

				</el-form>

				<el-table :data="tableData.rows">

					<el-table-column
						property="contractFileName"
						:label="$t('el.main.index.result.label1')"
					>

						<template slot-scope="scope">

							<el-tooltip
								effect="dark"
								:content="scope.row.contractFileName"
								placement="top"
								:manual="
									!absolutFlag(scope.row.contractFileName, 14)
								"
							>

								<div class="paramsOther">
									 {{ scope.row.contractFileName }}
								</div>

							</el-tooltip>

						</template>

					</el-table-column>

					<el-table-column
						property="projectName"
						width="120px"
						:label="$t('el.main.index.result.label2')"
					>

						<template slot-scope="scope">

							<el-tooltip
								effect="dark"
								:content="scope.row.projectName"
								placement="top"
								:manual="
									!absolutFlag(scope.row.projectName, 14)
								"
							>

								<div class="paramsOther">
									 {{ /默认项目/.test(scope.row.projectName) &&
									$i18n.locale === 'en_US' ? 'DemoProject' : scope.row.projectName
									}}
								</div>

							</el-tooltip>

						</template>

					</el-table-column>

					<el-table-column
						property="contractType"
						:label="$t('el.main.index.result.label3')"
					>

					</el-table-column>

					<el-table-column
						property="contractName"
						:label="$t('el.main.index.result.label4')"
						width="120px"
					>

						<template slot-scope="scope">

							<el-tooltip
								effect="dark"
								:content="scope.row.contractName"
								placement="top"
								:manual="
									!absolutFlag(scope.row.contractName, 14)
								"
							>

								<div class="paramsOther">
									 {{ scope.row.contractName }}
								</div>

							</el-tooltip>

						</template>

					</el-table-column>

					<el-table-column
						property="contractParams"
						:label="$t('el.main.index.result.label5')"
						width="140px"
					>

						<template slot-scope="scope">

							<el-tooltip
								effect="dark"
								:content="scope.row.contractParams"
								placement="top"
								:manual="
									!absolutFlag(scope.row.contractParams, 16)
								"
							>

								<div class="params">
									 {{ scope.row.contractParams }}
								</div>

							</el-tooltip>

						</template>

					</el-table-column>

					<el-table-column
						property="compilerVersion"
						:label="$t('el.main.index.result.label6')"
					>

					</el-table-column>

					<el-table-column
						property="startTime"
						:label="$t('el.main.index.result.label7')"
						width="140px"
					>

					</el-table-column>

					<el-table-column
						property="endTime"
						:label="$t('el.main.index.result.label8')"
						width="140px"
					>

					</el-table-column>

					<el-table-column
						property="status"
						:label="$t('el.main.index.result.label9')"
					>

						<template slot-scope="scope">

							<span>

								<my-svg
									:icon="
										scope.row.status === '01'
											? '#icon-zhixing'
											: scope.row.status === '02'
											? '#icon-zhongduan'
											: '#icon-wancheng'
									"
								/>
								 {{ foramtStatus(scope.row.status) }}
							</span>

						</template>

					</el-table-column>

					<el-table-column
						property="option"
						:label="$t('el.main.index.result.label10')"
					>

						<template slot-scope="scope">

							<span
								:class="{
									specialWord: scope.row.option.length > 1,
								}"
								@click="
									tableOption(
										scope.row,
										scope.row.option.length > 1
									)
								"
							>
								 {{ scope.row.option }}
							</span>

						</template>

					</el-table-column>

				</el-table>

				<div class="footer">

					<el-pagination
						:page-size="pageSize"
						background
						layout="total, prev, pager, next"
						@current-change="handPage"
						@prev-click="handPage"
						@next-click="handPage"
						:total="tableData.total"
						:current-page="pageNum"
					>

					</el-pagination>

					<span class="time">
						 5{{ $t("el.main.index.result.page") }}
					</span>

				</div>

			</el-dialog>

		</div>

	</div>

</template>

<script>
import Header from './components/header.vue'
import mySvg from './components/svg.vue'
import editor from './components/editor'
import Editor from './components/editor.vue'
import LeftNav from './components/leftNav'
import { mapState, mapMutations } from 'vuex'
import {
	getData,
	setData,
	$success,
	$warning,
	$error,
	groupArray,
	foramtStr,
	concatPath,
	absolutFlag,
} from '@/utils/save'
import {
	getTableList,
	checkType,
	free,
	getFileData,
	save,
	interrupt,
	resetDefaultFileById,
	queryDocument,
	gotoStopTesting,
} from '@/api/file.js'
import axios from 'axios'
import { getStore } from '../../utils/utils'
import eventBus from '@/utils/event-bus'
let language = getStore('language') ? getStore('language') : 'en_US'
export default {
	inject: ['toLogin'],
	data() {
		const validateFile = (rule, value, callback) => {
			if (!value) {
				callback(new Error(this.$t('el.main.index.form.placeholder5')))
			} else {
				callback()
			}
		}
		const validateName = (rule, value, callback) => {
			if (!value) {
				callback(new Error(this.$t('el.main.index.form.placeholder2')))
			} else {
				callback()
			}
		}
		const validateVersion = (rule, value, callback) => {
			if (!value) {
				callback(new Error(this.$t('el.main.index.form.placeholder4')))
			} else {
				callback()
			}
		}
		return {
			// 任务标识
			onlyNum: null,
			submitInfo: {
				file: '',
				name: '',
				params: '',
				version: '',
				// showResult:false,
			},
			rules: {
				file: [
					{
						required: true,
						validator: validateFile,
						trigger: 'blur',
					},
				],
				name: [
					{
						required: true,
						validator: validateName,
						trigger: 'blur',
					},
				],
				version: [
					{
						required: true,
						validator: validateVersion,
						trigger: 'blur',
					},
				],
			},
			//可以选择的文件
			files: [],
			//主合约参数
			params: [
				{
					placeholder: this.$t('el.main.index.form.placeholder3'),
					value: '',
					id: new Date().valueOf(),
				},
			],
			//编译器版本
			versions: [],
			curVersion: [],
			//阶段表单提交阶段和检测阶段 submit check   result
			// phase: "submit",
			phase: 'submit',
			// phase:"result",
			fileName: '',
			fileNames: '',
			pageNum: 1,
			pageSize: 5,
			tableData: {},
			percentage: 0,
			//提交检测的错误信息
			errMsg: '',
			//错误列表信息
			errList: [],
			//建议
			advice: '',
			// 建议修改
			description: '',
			// 错误详细信息文档地址
			wiki: '',
			//今日免费检测次数
			freeTime: 1,
			//是否显示主合约名称
			showName: true,
			//便于点击右边检测结果触发中间滑动
			currentType: '',
			//是否检测中
			testProgress: true,
			//是否显示修改后重新提交
			showReSubmit: true,
			//未检测到问题
			activeLine: null, // 激活的行
			// 当前按钮状态 0 停止检测，1 返回
			curResultType: 0,
			// 按钮名称
			resultTypeBtnStop: this.$t('el.main.index.desc.stop'),
			resultTypeBtnBack: this.$t('el.back'),
			// 当前的结果是否完成
			curResultIsNoFull: false,
			submitLoading: false,
			// 登录弹窗
			isLoginDialogVisible: false,
		}
	},
	filters: {
		/*foramtStatus(status) {
          switch (status) {
            /!*case "01":
              return this.$t('el.main.index.desc.checking');
            case "02":
              return this.$t('el.main.index.desc.interrupt');
            default:
              return this.$t('el.main.index.desc.checked');*!/
              case "01":
                  return "检测中";
              case "02":
                  return "中断";
              default:
                  return "检测完毕";
          }
        },*/

		formatLable(str) {
			const content = str.split('.')
			if (content[0].length > 5) {
				return (
					content[0].substring(0, 5) +
					'....' +
					(content[1] ? content[1] : '')
				)
			}
			return str
		},
	},
	components: {
		editor,
		Header,
		mySvg,
		Editor,
		LeftNav,
	},
	computed: {
		formatFileTooltip() {
			return function(str) {
				return /默认项目/.test(str) && this.$i18n.locale === 'en_US'
					? str.replace(/默认项目/, 'DemoProject')
					: str
			}
		},
		formatFile() {
			return function(str) {
				str =
					/默认项目/.test(str) && this.$i18n.locale === 'en_US'
						? str.replace(/默认项目/, 'DemoProject')
						: str
				return foramtStr(str)
			}
		},
		foramtStatus() {
			return function(status) {
				switch (status) {
					case '01':
						return this.$t('el.main.index.desc.checking')
					case '02':
						return this.$t('el.main.index.desc.interrupt')
					default:
						return this.$t('el.main.index.desc.checked')
				}
			}
		},
		editableTabsValue: {
			get() {
				return this.$store.state.editableTabsValue
			},
			set(newValue) {
				this.changeEditorValue(newValue)
			},
		},
		showResult: {
			get() {
				return this.$store.state.showResult
			},
			set(flag) {
				this.changeShowResult(flag)
			},
		},
		curOptions: {
			get() {
				return this.$store.state.curOptions
			},
			set(flag) {
				this.changeCurOption(flag)
			},
		},
		fileDate: {
			get() {
				return this.$store.state.fileDate
			},
			set(flag) {
				this.changeFileDate(flag)
			},
		},
		user() {
			return this.$store.state.user
		},

		...mapState(['editableTabs', 'tabIndex']),
	},
	methods: {
		...mapMutations([
			'addTab',
			'removeTab',
			'tabClick',
			'changeEditorValue',
			'changeShowResult',
			'changeCurOption',
		]),
		//添加主合约参数
		addParams(flag, id = 0) {
			if (flag) {
				this.params.push({
					value: '',
					id: new Date().valueOf(),
				})
			} else {
				this.params = this.params.filter((item) => item.id !== id)
			}
		},
		tabsClick(tab) {
			this.tabClick(tab)
			this.handleSwitchFile(tab.name)
		},
		// 切换文件（如果当前文件查询过，并且显示查询结果）
		handleSwitchFile(curName) {
			this.activeLine = null // 清空激活行样式
			const curFile = this.fileDate.find((v) => {
				return v.path === curName
			})
			if (curFile) {
				const curFileId = 'fileId_' + curFile.id
				if (sessionStorage.getItem(curFileId)) {
					this.phase = 'result'
					const curResult = JSON.parse(
						sessionStorage.getItem(curFileId)
					)
					this.errList = curResult.errList
					this.curResultIsNoFull = curResult.isAuditTimeout || false
					return
				}
				this.phase = 'submit'
				this.errList = []
				this.curResultIsNoFull = false
				return
			}
		},
		//到错误的哪一行去查看
		toLine(line, title, file, i) {
			this.activeLine = file + i
			//先判断文件是否删除
			// const isDel = JSON.parse(getData("fileDate")).find(
			//   (item) => item.path == file
			// );
			// console.log(line,'saassasa')
			// debugger;
			const isDel = this.$store.state.fileDate.find(
				(item) => item.path == file
			)

			if (!isDel) {
				$error(this.$t('el.fileNoExist'))
				return
			}
			const scrol = this.$('.ace_scrollbar.ace_scrollbar-v ')
			//移除事件
			scrol.unbind()
			const self = this
			let time = 0
			const fileName = file.split('/')[file.split('/').length - 1]

			this.$store.state.treeNode.setCurrentKey(file)
			let expand = JSON.parse(getData('expandedKeys'))
			if (!expand.some((item) => item === file)) {
				expand = [...expand, ...concatPath(file)]
			}
			// console.log(111)
			this.$store.state.leftNav.expandedKeys = expand
			setData('expandedKeys', JSON.stringify(expand))

			// const { content } = JSON.parse(getData("fileDate")).find(
			//   (item) => item.path === file
			// );

			const { content } = self.$store.state.fileDate.find(
				(item) => item.path === file
			)
			//需要判断是否需要切换当前文件
			const isToggle = file == this.$store.state.editableTabsValue

			if (!isToggle) {
				//先要判断有没有这个标签
				const haveTab = self.$store.state.editableTabs.some(
					(item) => item.name === file
				)

				if (!haveTab) {
					this.$store.commit('changeEditableTab', {
						title: fileName,
						name: file,
					})
				}
				this.$store.commit('changeEditableTabsValue', file)
				time = 1000
			}

			fn1()

			async function fn1() {
				if (!isToggle) {
					await self.$store.state.editor.setValue(content)
				}
				await self.$store.state.editor.gotoLine(line)
			}

			async function fn() {
				//先让所有的变色
				setTimeout(() => {
					const lines = self.$('.ace_line')
					const num = self.$('.ace_gutter-cell')
					for (let i = 0; i < num.length; i++) {
						if (num[i].innerText == line) {
							self.$(lines[i])
								.css({ background: 'red' })
								.siblings('div')
								.css({ background: 'transparent' })
						}
					}
				}, time)
			}

			fn()
			//这一行的错误描述
			const curAdvice = this.errList.find((item) => item.file === file)
				.data
			const curInfo = curAdvice.find(
				(item, index) =>
					item.line == line && item.title == title && index === i
			)
			const lang = getStore('language')
			this.advice =
				lang === 'en_US'
					? curInfo.advice || ''
					: curInfo.advice_zh || ''
			this.description = curInfo.description
			this.wiki = curInfo.wiki_zh

			//需要添加滚动事件，让滚动的时候能够显示
			//  let arr=[line.toString()];

			scrol.bind('scroll', fns)

			function fns() {
				const lines = self.$('.ace_line')
				const nums = self.$('.ace_gutter-cell')
				for (let i = 0; i < nums.length; i++) {
					if (nums[i].innerText == line) {
						self.$(lines[i])
							.css({ background: 'red' })
							.siblings('div')
							.css({ background: 'transparent' })
					}
				}
			}
		},
		//开始检测
		submit() {
			if (!this.user) {
				this.toLogin()
				return
			}
			if (!this.freeTime) {
				return $error(this.$t('el.main.index.limit'))
			}
			const self = this
			self.testProgress = true
			this.showReSubmit = true
			this.percentage = 0
			this.curResultType = 0
			this.curResultIsNoFull = false
			this.errMsg = ''
			this.$store.commit('changeShowTestResult', true)
			this.$refs['submitInfo'].validate((valid) => {
				if (!valid) return
				const { file, name, params, version } = this.submitInfo
				//获得当前需要操作的文件
				// const { content, type, label, id } = JSON.parse(
				//   getData("fileDate")
				// ).find((item) => item.path === file);

				const {
					content,
					type,
					label,
					id,
				} = self.$store.state.fileDate.find(
					(item) => item.path === file
				)
				const paramsArr = this.params
					.filter((item) => item.value.trim())
					.map((item) => item.value)
				sessionStorage.setItem('type', type)
				// const node = JSON.parse(getData("fileDate")).find(
				//   (item) => item.path === file.split("/")[0]
				// );
				const node = self.$store.state.fileDate.find(
					(item) => item.path === file.split('/')[0]
				)
				let submitInfo = {
					projectId: node.id,
					projectName: node.path,
					platform: type,
					version,
					contractName: name,
					fileName: label,
					//content,
					fileId: id,
					deployArgs: paramsArr,
				}
				self.currentType = type

				if (!content) {
					return $error(this.$t('el.main.index.contractErr'))
				}
				this.phase = 'check'
				// 按10分钟来
				const timer = setInterval(() => {
					if (self.percentage < 99) {
						self.percentage += 1
					} else {
						clearInterval(timer)
					}
				}, 1000 * 6)

				//批量保存
				let paths = this.$store.state.editableTabs.map(
					(item) => item.name
				)
				let upload = self.$store.state.fileDate.filter(
					(item) => paths.indexOf(item.path) !== -1
				)
				this.onlyNum = `${submitInfo.projectId}_${new Date().getTime()}`
				upload = upload.map((item) => {
					const { id, label, type, content } = item
					return {
						contractFileId: id,
						contractFileName: label,
						contractType: type,
						code: content,
					}
				})
				save([...upload])
					.then((res) => {
						return axios
							.post(
								process.env.VUE_APP_BASE_API +
									'/new-vaas/contract/audit',
								{
									...submitInfo,
									systemCode: 'vaas_system',
									task: this.onlyNum,
								},
								{
									headers: {
										'Content-Type':
											'application/json;charset=utf-8',
										Authorization: `Bearer ${localStorage.getItem(
											'token'
										)}`,
										'Accept-Language': language,
									},
								}
							)
							.then((res) => {
								if (res.data.code === 401) {
									localStorage.removeItem('token')
									window.location.reload()
									return
								}
								if (res.code === 500 || res.data.code === 500) {
									self.curResultType = 1
									self.showReSubmit = false
									self.percentage = 100
									self.testProgress = false
									self.submitInfo = {
										file: '',
										name: '',
										params: '',
										version: '',
									}
									self.params = [
										{
											placeholder: this.$t(
												'el.main.index.form.placeholder3'
											),
											value: '',
											id: new Date().valueOf(),
										},
									]
									return (self.errMsg = this.$t('el.sysErr'))
								}

								self.testProgress = false
								const { data } = res
								const { msg, code } = data
								if (code !== 200) {
									self.curResultType = 1
									self.errMsg = msg
								} else if (code === 200) {
									if (self.$store.state.showTestResult) {
										self.phase = 'result'
									}
									const {
										result,
										id,
										startTime,
										dataType,
									} = data.data
									let arrTime = startTime.split('T')
									let remain = arrTime[1].split('.')
									let times = arrTime[0] + ' ' + remain[0]

									let row = { startTime: times }
									sessionStorage.setItem(
										'row',
										JSON.stringify(row)
									)
									//设置id便于查看结果操作
									sessionStorage.setItem('id', id.toString())
									sessionStorage.setItem('dateType', dataType)
									const errList = []
									result.forEach((item) => {
										errList.push(...item.resultList)
									})
									errList.sort(
										(first, second) =>
											first.line - second.line
									)
									self.errList = groupArray(errList, 'file')
									const fileIdStr =
										'fileId_' + submitInfo.fileId
									const fileIdObjInfo = {
										errList: self.errList,
										id: id.toString(),
										dateType: dataType,
										type,
										isAuditTimeout:
											data.data.is_audit_timeout,
									}
									sessionStorage.setItem(
										fileIdStr,
										JSON.stringify(fileIdObjInfo)
									)
									if (data.data.is_audit_timeout) {
										this.curResultIsNoFull = true
										self.$alert(
											this.$t('el.tipInfo'),
											this.$t('el.tip'),
											{
												confirmButtonText: this.$t(
													'el.dialog.know'
												),
												callback: () => {},
											}
										)
									}
									//  //需要获取内容的content
									//    const contents=self.errList.map(item=>item.file);
									self.freetime()
								}
							})

							.finally(() => {
								clearInterval(timer)
							})
					})
					.catch((err) => {
						self.curResultType = 1
						self.showReSubmit = false
						self.percentage = 100
						self.testProgress = false
						self.submitInfo = {
							file: '',
							name: '',
							params: '',
							version: '',
						}
						self.params = [
							{
								placeholder: this.$t(
									'el.main.index.form.placeholder3'
								),
								value: '',
								id: new Date().valueOf(),
							},
						]
						self.errMsg = err
					})
			})
		},
		//格式化表格数据
		format(row, colum) {
			return 1
		},
		//监测用户是否选中文件
		examine() {
			this.$store.state.leftNav.showRightMenu = false
		},
		//对后端返回数据的处理
		handDate(rows, total) {
			rows = rows.map((item) => {
				for (let k in item) {
					if (!item[k] && k !== 'dateType') {
						item[k] = '/'
					}
				}
				const { status } = item
				let option = '/'
				if (status === '01') {
					option = this.$t('el.main.index.desc.interruptCheck')
				} else if (status === '03') {
					option = this.$t('el.main.index.desc.reportScan')
				} else if (status === '04') {
					option = this.$t('el.main.index.desc.reportScan')
				}
				return { ...item, option }
			})
			this.tableData = { rows, total }
		},
		//表格数据变化
		handPage(cur) {
			this.pageNum = cur
			const { fileName, pageNum, pageSize } = this
			this.queryTable(fileName, pageNum, pageSize)
		},
		//查询表格数据
		queryTable(fileName, pageNum, pageSize) {
			getTableList({ fileName, pageNum, pageSize }).then((res) => {
				if (res.code === 200) {
					let { rows, total } = res

					this.handDate(rows, total)
				}
			})
		},
		//文件名称查询
		search() {
			this.pageNum = 1
			this.fileName = this.fileNames
			const { fileName, pageNum, pageSize } = this
			this.queryTable(fileName, pageNum, pageSize)
		},
		//表格操作
		tableOption(row, flag) {
			if (!flag) return
			const self = this
			const { contractType, id, dateType } = row
			//  return ;
			if (row.status === '01') {
				this.errMsg = ''
				interrupt({ id: row.id }).then((res) => {
					this.queryTable(this.fileName, this.pageNum, this.pageSize)
					self.phase = 'submit'
					self.$store.commit('changeShowTestResult', false)
					//这里需要清空一下状态
					//  const  {file,name,params,version}=this.submitInfo;
					self.submitInfo = {
						file: '',
						name: '',
						params: '',
						version: '',
					}
					self.params = [
						{
							placeholder: this.$t(
								'el.main.index.form.placeholder3'
							),
							value: '',
							id: new Date().valueOf(),
						},
					]
				})
			} else {
				sessionStorage.setItem('row', JSON.stringify(row))
				this.changeShowResult(false)
				checkType({
					platform: contractType.toLowerCase(),
					dateType: dateType,
				}).then((res) => {
					if (res.code === 200) {
						sessionStorage.setItem(
							'errType',
							JSON.stringify(res.data)
						)
						sessionStorage.setItem('id', id.toString())
						sessionStorage.setItem('dateType', dateType)

						const { href } = this.$router.resolve({
							path: '/result',
						})
						window.open(href, '_blank')
					}
				})
			}
		},
		//查看结果
		examineResult() {
			const fileIdStr = 'fileId_' + this.curOptions.id
			const saveResult = sessionStorage.getItem(fileIdStr)
				? JSON.parse(sessionStorage.getItem(fileIdStr))
				: ''
			if (!saveResult) return
			const platform = saveResult.type
			const dateType = saveResult.dateType
			checkType({
				platform,
				dateType,
			}).then((res) => {
				if (res.code === 200) {
					sessionStorage.setItem('errType', JSON.stringify(res.data))
					sessionStorage.setItem('id', saveResult.id)
					sessionStorage.getItem('dateType', dateType)
					// sessionStorage.setItem('id',id.toString());
					const { href } = this.$router.resolve({
						path: '/result',
					})
					window.open(href, '_blank')
				}
			})
		},
		//点击保存按钮
		save() {
			//获得数据
			let paths = this.$store.state.editableTabs.map((item) => item.name)
			// let upload = JSON.parse(getData("fileDate")).filter(
			//   (item) => paths.indexOf(item.path) !== -1
			// );

			let upload = this.$store.state.fileDate.filter(
				(item) => paths.indexOf(item.path) !== -1
			)
			upload = upload.map((item) => {
				const { id, label, type, content } = item
				return {
					contractFileId: id,
					contractFileName: label,
					contractType: type,
					code: content,
				}
			})
			if (upload.length === 0) {
				// return $error("没有更改的内容");
			}
			save([...upload]).then((res) => {
				$success(this.$t('el.saveSuccess'))
			})
		},
		// 点击重置按钮
		handleReset() {
			const datas = {
				contractFileId: this.curOptions.id,
			}
			resetDefaultFileById(datas).then((res) => {
				$success(this.$t('el.resetSuccess'))
				queryDocument(datas).then((res) => {
					if (!res.data) return
					const content = res.data.code ? res.data.code : ''
					this.$store.commit('gotoReset', content)
				})
			})
		},
		//检测返回
		examineReturn() {
			this.submitInfo = { file: '', name: '', params: '', version: '' }
			this.params = [
				{
					placeholder: this.$t('el.main.index.form.placeholder3'),
					value: '',
					id: new Date().valueOf(),
				},
			]
			this.phase = 'submit'
			//清除红线和消除滚动事件
			const scrol = this.$('.ace_scrollbar.ace_scrollbar-v ')
			scrol.unbind()
			this.$('.ace_line').css({ background: 'transparent' })
			this.advice = ''
			this.description = ''
			// 返回清空已经保存的结果
			const curFile = this.fileDate.find((v) => {
				return v.path === this.files[0].value
			})
			if (curFile) {
				const curFileId = 'fileId_' + curFile.id
				sessionStorage.removeItem(curFileId)
				this.errList = []
				this.submitInfo.file = this.files[0].value
			}
		},
		//剩余免费次数
		freetime() {
			if (!localStorage.token) return
			free({ systemCode: 'vaas_system' }).then((res) => {
				this.freeTime = res.data
			})
		},
		//清空表单
		clearForm(curFileName) {
			this.submitInfo = { file: '', name: '', params: '', version: '' }
			this.params = [
				{
					placeholder: this.$t('el.main.index.form.placeholder3'),
					value: '',
					id: new Date().valueOf(),
				},
			]
			this.handleSwitchFile(curFileName)
		},
		//显示主合约名称
		changeShowName(flag) {
			//触发了这里
			this.showName = flag
		},
		absolutFlag(str, num) {
			return absolutFlag(str, num)
		},
		// 停止检测
		handleStopTesting() {
			this.submitLoading = true
			const data = {
				task: this.onlyNum,
			}
			gotoStopTesting(data).then((res) => {
				this.submitLoading = false
				this.phase = 'submit'
				this.percentage = 0
				this.errMsg = ''
			})
		},
		// 返回
		handleBack() {
			this.phase = 'submit'
			this.percentage = 0
			this.errMsg = ''
		},
		open(url) {
			window.open(url, 'errDetailDoc')
		},
	},
	watch: {
		curOptions(v) {
			//这里获得可以选择的文件范围，先判断是否有存在有文件
			const self = this
			const fileDate = this.$store.state.fileDate
			if (!fileDate.length) return

			//获得当前项目下的文件
			const { path, level, category } = this.curOptions
			//path最后路径
			if (!path) return
			const pathArr = path.split('/')
			const pathEnd = pathArr[pathArr.length - 1]
			const beginPath = pathArr[0]
			let selectFile = []
			if (category !== 'document') {
				if (category === 'folder') {
					selectFile = fileDate.filter(
						(item) =>
							item.path.indexOf(path) !== -1 &&
							item.category === 'document' &&
							item.level !== level &&
							pathEnd === item.path.split('/')[pathArr.length - 1]
					)
				} else {
					selectFile = fileDate.filter(
						(item) =>
							item.path.indexOf(path) !== -1 &&
							item.category === 'document' &&
							item.level !== level &&
							beginPath === item.path.split('/')[0]
					)
				}
			} else {
				selectFile = fileDate.filter((item) => item.path === path)
			}
			this.files = selectFile.map((item) => ({
				label: item.label,
				value: item.path,
			}))
			const { type } = self.$store.state.fileDate.find(
				(item) => item.path === path
			)
			if (type === 'fabric') {
				this.changeShowName(false)
			}
			if (this.files.length) {
				this.submitInfo.file = this.files[0].value
			}
			//选择版本
			this.curVersion = this.version[type]
		},
		showResult() {
			const flag = this.$store.state.showResult
			const { fileName, pageNum, pageSize } = this
			const self = this
			if (flag) {
				this.queryTable(fileName, pageNum, pageSize)
			}
		},
		fileNames() {
			if (!this.fileNames) {
				this.search()
			}
		},
		user() {
			this.$store.commit('changeEditableTabs', [])
			this.freetime()
			this.clearForm()
			this.files = []
			// Object.keys(this.submitInfo).forEach(key=>{
			//     this.submitInfo[key] = ''
			// })
		},
	},
	created() {
		this.freetime()
		const version = {
			fabric: [{ value: '1.13.6' }],
			bcos: [],
			eth: [],
			chainmaker: [],
			antchain: [{ value: 'alipay-solc-0.1.12' }],
		}
		eventBus.$on('langChange', () => {
			this.$refs['submitInfo']?.clearValidate()
		})
		this.$nextTick(() => {
			this.$store.state.editor.setValue(
				this.$t('el.main.index.form.placeholder5')
			)
			this.$store.state.editor.gotoLine(1)
			this.$store.state.editor.setReadOnly(true)
		})
		for (let i = 13; i >= 0; i--) {
			version.eth.push({ value: `0.8.${i}` })
		}
		for (let i = 6; i >= 0; i--) {
			version.eth.push({ value: `0.7.${i}` })
		}
		for (let i = 12; i >= 0; i--) {
			version.eth.push({ value: `0.6.${i}` })
		}
		for (let i = 17; i >= 0; i--) {
			version.eth.push({ value: `0.5.${i}` })
		}
		for (let i = 25; i >= 12; i--) {
			version.eth.push({ value: `0.4.${i}` })
		}
		version.bcos = version.chainmaker = version.eth
		this.version = version
	},
	mounted() {
		this.$store.commit('changeTotalComponent', this)
	},
}
</script>

<style scoped lang="scss">
.wx-content {
	display: flex;

	.tip-title {
		color: #fff;
		margin-bottom: 6px;
	}

	.wx-img {
		width: 120px;
	}

	.wx-item {
		&:last-child {
			margin-left: 10px;
		}
	}
}

.err-doc-url {
	&:hover {
		text-decoration: underline;
	}

	cursor: pointer;
	color: #4084ff;
}

.inner {
	width: 100%;
	height: 100vh;
	background-color: #141720;
	min-width: 1200px;

	.middle {
		width: 100%;
		height: calc(100vh - 67px);
		display: flex;

		.right {
			width: 310px;
			height: calc(100vh - 77px);
			margin: 0 0 10px 0;
			background: #2f3544;
			border-radius: 6px;
		}

		.left {
			// width: 300px;
		}

		.center {
			flex: 1;
			margin: 0 10px 10px;
			display: flex;
			flex-direction: column;
			overflow: hidden;

			.editor {
				flex: 2.7;
				width: 100%;
				background: #272c37;
				// margin-bottom: 10px;
				border-radius: 6px;
				display: flex;
				flex-direction: column;

				.tab {
					height: 52px;
					width: 100%;
					display: flex;

					::v-deep .el-table .cell {
						text-align: center;
					}

					::v-deep .tabS {
						flex: 4.5;
						overflow: hidden;

						.el-tabs.el-tabs--card.el-tabs--top {
							width: 100%;
							overflow: hidden;
						}

						.el-tabs__header {
							margin: 0;
						}

						.el-tabs--card > .el-tabs__header {
							border: none;
						}

						.el-tabs--card > .el-tabs__header .el-tabs__item {
							border: none;
						}

						.el-tabs--card > .el-tabs__header .el-tabs__nav {
							border: none;
						}

						.el-icon-arrow-right::before,
						.el-icon-arrow-left::before {
							content: '';
						}

						.el-tabs__item {
							color: #d3dcf5;
							font-size: 14px;
							width: 206px;
							// width: 59%;
							height: 52px;
							line-height: 52px;
							text-align: center;

							//  white-space: nowrap;
							// overflow: hidden;
							// text-overflow:ellipsis;
						}

						.el-tabs__item.is-active {
							background: #343c4f;
						}
					}

					::v-deep .saveBtn {
						flex: 1;
						display: flex;
						justify-content: flex-end;

						.saveOth {
							padding-right: 16px;
							padding-top: 18px;

							.reset-btn {
								font-size: 24px;
								color: white;
							}
						}

						.icon {
							font-size: 22px;
							color: white;
							//   padding-right: 16px;
							//  padding-top:18px;
						}

						.icon:hover {
							color: #465ebf;
							cursor: pointer;
						}

						.el-tooltip:hover {
							color: #465ebf;
							cursor: pointer;
						}
					}

					// background-color: yellow;
				}

				.edit-area {
					width: 100%;
					flex: 1;
					padding: 0 16px 16px 0;
					box-sizing: border-box;
					// overflow: auto;
				}
			}

			.bottom {
				flex: 1;
				width: 100%;
				background: #272c37;
				border-radius: 6px;
				padding: 0 27px;
				margin-top: 10px;
				box-sizing: border-box;
				overflow: auto;

				.title {
					color: #ffffff;
					font-size: 14px;
					font-weight: 400;
					padding-top: 26px;
				}

				.word {
					font-size: 14px;
					font-weight: 400;
					line-height: 26px;
					color: #d3dcf5;
					text-align: justify;
				}
			}
		}

		.right {
			width: 330px;
			overflow: auto;
			position: relative;

			.form {
				padding: 25px 0 0 33px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				height: 100%;

				.el-form {
					flex: 1 0 auto;
				}

				.special {
					margin-top: 20px;
				}

				//  .addArea:last-child{
				//        margin-bottom: 20px;
				//  }
				.flexs {
					display: flex;

					//  margin-bottom: 20px;
					::v-deep .icon {
						font-size: 20px;
						color: #6e758a;
						cursor: pointer;
						border-radius: 50%;
						margin-left: 8px;
					}

					::v-deep .icon:hover {
						color: #d3dcf5;
					}

					&.unusual {
						margin-top: 17px;
					}
				}

				// .flexs:last-child{
				//    margin-bottom: 0  !important;
				// }
				.remain {
					font-size: 14px;
					font-weight: 400;
					line-height: 19px;
					color: #ffffff;
					margin: 15px 0 0 47px;
					flex: 0 0 auto;
				}

				.word-content {
					margin: 31px 0 0 0;
					padding-bottom: 15px;
					flex: 0 0 auto;

					.el-icon-phone {
						border-radius: 50%;
						background: #d3dcf5;
						color: #2f3544;
						width: 20px;
						height: 20px;
						text-align: center;
						line-height: 20px;
					}

					.title {
						font-weight: bold;
						font-size: 18px;
						color: white;
						margin-bottom: 18px;
					}

					.info {
						width: 276px;
						font-size: 14px;
						font-weight: 400;
						line-height: 19px;
						color: #d3dcf5;
						margin-bottom: 10px;

						span {
							color: #448aff;
						}
					}
				}

				.el-input__suffix {
					top: 3px !important;
				}

				::v-deep .el-input__inner {
					height: 34px;
					color: #fff !important;
					font-size: 14px;
					font-weight: 400;
					background-color: #2f3544;
					// margin-bottom: 25px;
					margin-top: 0px;
					line-height: 34px;
					border-color: #6e758a;

					&:hover {
						border-color: #fff !important;
					}
				}

				::v-deep .el-input .el-select__caret.is-reverse {
					transform: translate(0px, -5px) rotateZ(0);
				}

				::v-deep .el-form-item__content {
					line-height: 34px !important;
				}

				::v-deep .el-select {
					width: 268px;
					height: 40px;

					.el-input__inner {
						height: 34px;
						color: #fff;
						font-size: 14px;
						font-weight: 400;
						background-color: #2f3544;
						// margin-top: 20px;
					}

					// ::v-deep .el-input__prefix,
					// ::v-deep .el-input__suffix {
					//   top: 9px !important;
					// }
				}

				::v-deep .el-select-dropdown__item {
					background: #676f84;
					color: #d3dcf5;
					font-size: 14px;
					font-weight: 400;
					box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
					border-bottom: 1px solid #d4d6df;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					width: 266px;
				}

				::v-deep .el-scrollbar__view.el-select-dropdown__list {
					padding: 0 !important;
				}

				::v-deep .el-select-dropdown.el-popper {
					left: 0 !important;
					margin: 0 !important;
					top: 36px !important;
				}

				::v-deep .el-popper .popper__arrow {
					display: none !important;
				}

				::v-deep .el-select-dropdown__item.hover {
					color: #1d2239;
					background-color: #fff;
				}

				.test {
					width: 174px;
					height: 34px;
					background: #4664bf;
					border-radius: 3px;
					line-height: 34px;
					text-align: center;
					font-size: 14px;
					color: #ffffff;
					margin: 15px 0 0 47px;
					cursor: pointer;
					border-radius: 3px;
				}

				.testSpecial {
					background: #676f84;
					color: #141620;
					cursor: no-drop;
				}
			}

			.check {
				margin: 40px 0 20px 28px;

				.circle {
					width: 260px;
					height: 260px;
					background: url('../../assets/images/circle.png') no-repeat
						center;
					background-size: cover;
					margin-left: 7px;
					display: flex;
					justify-content: center;
					align-items: center;

					::v-deep .el-progress-circle {
						width: 189px !important;
						height: 189px !important;
					}

					::v-deep .el-progress__text {
						font-size: 25px;
						font-weight: 600;
						color: white;
					}

					::v-deep .el-progress-circle svg path:first-child {
						stroke: #48597a;
					}

					::v-deep .el-table .cell {
						text-align: center;
					}
				}

				::v-deep .el-table__row {
					.cell {
						text-align: center !important;
					}
				}

				.desc {
					margin: 0 0 20px 82px;
					font-size: 16px;
					font-weight: bold;
					color: #ffffff;
				}

				.step {
					margin-left: 113px;
					font-size: 14px;
					font-weight: 400;
					line-height: 26px;
					color: #d3dcf5;
				}

				.error {
					color: #e0586f;
					font-weight: 400;
					font-size: 14px;
					line-height: 26px;
					margin-right: 20px;
					text-align: center;
				}

				.plase {
					margin-left: 82px;
					font-size: 14px;
					font-weight: 400;
					line-height: 19px;
					color: white;
				}

				.return {
					width: 120px;
					height: 31px;
					background: #465ebf;
					border-radius: 6px;
					font-size: 14px;
					font-weight: 400;
					line-height: 31px;
					text-align: center;
					color: #ffffff;
					margin: 42px 0 0 80px;
					cursor: pointer;
				}

				.submit-btn {
					display: flex;
					justify-content: center;
					margin-top: 40px;

					.el-button {
						width: 120px;
						height: 31px;
						padding: 0;
						background: #465ebf;
						border-radius: 6px;
						font-size: 14px;
						font-weight: 400;
						line-height: 31px;
						color: #ffffff;
						border: 1px solid #465ebf;
					}
				}
			}

			.res {
				display: flex;
				flex-direction: column;
				height: 100%;

				.number {
					font-size: 29px;
					font-weight: bold;
					line-height: 39px;
					color: #6376d6;
					text-align: center;
					margin-top: 22px;

					span:nth-child(2) {
						font-weight: 300;
						font-size: 22px;
						color: #fff;
					}
				}

				.word {
					margin-bottom: 20px;
					font-size: 22px;
					font-weight: bold;
					line-height: 29px;
					color: #ffffff;
					text-align: center;
				}

				.sub-msg {
					padding: 0 10px;
					font-size: 13px;
					color: #ffffff;
					line-height: 24px;
				}

				.error {
					overflow: auto;
					margin: 10px 12px 0;
					background: #272b37;
					border-radius: 6px;
					width: 306px;
					box-sizing: border-box;
					padding-top: 25px;
					flex: 1 1 auto;

					.line {
						font-size: 14px;
						font-weight: 400;
						line-height: 26px;
						color: #ffffff;
						cursor: pointer;
						word-break: break-all;
					}

					.active-line {
						background-color: #4084ff;
					}
				}

				.bottom-btn {
					display: flex;
					justify-content: space-around;
					align-items: center;
					flex: 0 0 auto;
					height: 60px;
				}

				.btn-arrea {
					width: 100px;
					height: 31px;
					background: #4661bf;
					border-radius: 6px;
					font-size: 14px;
					font-weight: 400;
					line-height: 31px;
					color: #ffffff;
					text-align: center;
					cursor: pointer;

					&:hover {
						background: #587bff;
					}

					&.btn-special {
						background: #676f84;
					}

					&.btn-special:hover {
						background: #a5afc9;
					}
				}
			}
		}
	}

	::v-deep .check {
		.el-dialog__header {
			height: 60px;
			padding: 0;
			background-color: #fff;
			line-height: 60px;

			span {
				color: #30364e;
				font-size: 18px;
				font-weight: 700;
				margin-left: 30px;
			}

			span:nth-child(2) {
				font-size: 16px;
				font-weight: 400;
				margin-left: 10px;
			}
		}

		.el-dialog__body {
			background: #f6f7f9;

			thead {
				th {
					background: #696e7d;
					color: #ffffff;
					font-size: 14px;
					font-weight: 400;
					height: 51px;
					line-height: 51px;
					padding: 0;
				}

				.cell {
					text-align: center !important;
				}
			}

			tbody {
				border: 1px solid #636977;

				tr {
					.params {
						width: 130px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.paramsOther {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.specialWord {
						color: #4084ff;
						cursor: pointer;
					}

					td {
						color: #696e7d;
						background: #f6f7f9;
						font-size: 12px;
						font-weight: 400;
						height: 46px;
						line-height: 46px;
						padding: 0;
					}
				}

				.cell {
					text-align: center !important;
				}
			}

			.footer {
				display: flex;
				justify-content: flex-end;
				margin-top: 20px;

				.el-pager {
					.active {
						background: #343a4f;
						min-width: 24px;
						width: 24px;
						height: 24px;
						line-height: 24px;
					}
				}

				.time {
					margin-top: 6px;
					color: #696e7d;
					font-size: 14px;
					font-weight: 400;
				}
			}
		}
	}
}

//导航条样式
.right::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	background-color: #3d4659;
}

.right::-webkit-scrollbar-thumb {
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
	background-color: #3d4659;
}

.right::-webkit-scrollbar-track {
	-webkit-box-shadow: insert 0 0 5px #3d4659;
	box-shadow: 0;
	background-color: #343c4f;
}

.bottom::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.bottom::-webkit-scrollbar-thumb {
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
	background-color: #3d4659;
}

.bottom::-webkit-scrollbar-track {
	-webkit-box-shadow: insert 0 0 5px #3d4659;
	box-shadow: 0;
	background-color: #343c4f;
}

.error::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.error::-webkit-scrollbar-thumb {
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.6);
	background-color: #3d4659;
}

.error::-webkit-scrollbar-track {
	-webkit-box-shadow: insert 0 0 5px #3d4659;
	box-shadow: 0;
	background-color: #343c4f;
}

::v-deep .middle .right .form .el-select .el-input__suffix {
	top: 3px !important;
}

.placeholder3E {
	width: 250px !important;
}

.placeholder3E ::v-deep .el-input__inner {
	font-size: 12px !important;
}

.info-tip {
	display: flex;
	align-items: center;

	img {
		width: 20px;
	}

	a {
		margin-left: 10px;
		color: #fff;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}
</style>

